// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchFileRetention, postFileRetention } from 'actions/FileRetentionActions';
import FileRetention from './FileRetention';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const {
    currentTeam,
    uiTeamSettings,
  } = state.Ui;
  return {
    fileRetentionData: teamSettings.fileRetention,
    currentTeam,
    uiFileRetention: uiTeamSettings.fileRetention,
  };
};

const mapDispatchToProps = {
  fetchFileRetention,
  postFileRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileRetention);
