// Copyright 2019, White Label Communications, LLC, All rights reserved.
import React, { PureComponent } from 'react';
import { Tabs, Tab, Table } from 'react-bootstrap';

export default class UsageData extends PureComponent {
  render() {
    return (
      <Tabs defaultActiveKey="teammembers" id="boomea_metrics_data">
        <Tab eventKey="teammembers" title="Team Members">
          <Table>
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Add On</th>
                <th>Last Activity</th>
                <th className="text-right">Total Messages</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="channels" title="Channels">
          <Table>
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Add On</th>
                <th>Last Activity</th>
                <th className="text-right">Total Messages</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="tasks" title="Tasks">
          <Table>
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Add On</th>
                <th>Last Activity</th>
                <th className="text-right">Total Messages</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="notes" title="Notes">
          <Table>
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Add On</th>
                <th>Last Activity</th>
                <th className="text-right">Total Messages</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
              <tr>
                <td>Tamra Rumsey</td>
                <td className="text-center">06/02/2020</td>
                <td className="text-center">06/30/2020</td>
                <td className="text-right">356</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    );
  }
}
