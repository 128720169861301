// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';

export default function PageNoMatch() {
  return (
    <>
      AccessDenied
    </>
  );
}
