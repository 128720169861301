// Copyright 2019, White Label Communications, LLC, All rights reserved.

export function ConvertArrayOfObjectsWithIdToArrayOfIds(records) {
  const retArray = [];
  const arrayLength = records.length;
  for (let i = 0; i < arrayLength; i += 1) {
    retArray.push(records[i].id);
  }
  return retArray;
}

export function ConvertArrayOfObjectsToObjectOfIdWithObject(records) {
  const retObject = {};
  const arrayLength = records.length;
  for (let i = 0; i < arrayLength; i += 1) {
    retObject[records[i].id] = records[i];
  }
  return retObject;
}

export function ConvertArrayOfObjectsToObjectOfTeamIdWithObject(records) {
  const retObject = {};
  const arrayLength = records.length;
  for (let i = 0; i < arrayLength; i += 1) {
    retObject[records[i].team_id] = records[i];
  }
  return retObject;
}

export function ConvertArrayOfObjectsToObjectOfIdWithObjectAndCurrentState(currentState, records) {
  const retObject = {};
  const arrayLength = records.length;
  for (let i = 0; i < arrayLength; i += 1) {
    retObject[records[i].id] = { ...currentState[records[i].id], ...records[i] };
  }
  return retObject;
}

export function removeFromObjByKey(myObj, deleteKey) {
  return Object.assign(
    {},
    ...Object.entries(myObj)
      .filter(([k]) => k !== deleteKey)
      .map(([k, v]) => ({ [k]: v })),
  );
}
