// Copyright 2019, White Label Communications, LLC, All rights reserved.
import { combineReducers } from 'redux';
import setting from './Setting';
import list from './List';
import detail from './Detail';

export default combineReducers({
  setting,
  list,
  detail,
});
