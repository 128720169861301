// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Media, Dropdown } from 'react-bootstrap';
import { imageURLForUser, getUserType } from 'components/Common/Utils';
// import Avatar from 'images/avatar.svg';
import DotsMenu from 'images/dots.svg';


import presence from 'images/appicons/presence.svg';
import appphone from 'images/appicons/phone.svg';

import appchat from 'images/appicons/chat.svg';
import callcenter from 'images/appicons/head-phones.svg';
import conferenceCenter from 'images/appicons/conference-center.svg';
import apprecord from 'images/appicons/voice-mail.svg';
import QuickCall from 'images/appicons/quick-call.svg';
import companyDirectory from 'images/appicons/company-directory.svg';
import contactsImage from 'images/appicons/contacts.svg';
import meetingIcon from 'images/appicons/meeting.svg';
import task from 'images/appicons/task.svg';
import notes from 'images/appicons/note-icon.svg';

const TeamList = ({
  id,
  billingController,
  user,
  showConfirmModal,
  handleUserEditAction,
  handleUserSuspendAction,
}) => {
  const userType = getUserType(user);
  const menus = [];

  if (billingController.toLowerCase() === 'boomea') {
    menus.push(<Dropdown.Item href="#/action-1" onClick={(e) => handleUserEditAction(e, id)}>Edit</Dropdown.Item>);

    menus.push(<Dropdown.Item href="#/action-2" onClick={handleUserSuspendAction}>Suspend</Dropdown.Item>);
    // eslint-disable-next-line max-len
    menus.push(<Dropdown.Item href="#/action-3" onClick={(e) => showConfirmModal(e, user.id, 'user_delete')}>Delete</Dropdown.Item>);
  }

  return (
    <tr>
      <td>
        <Media>
          {
          // eslint-disable-next-line max-len
          `${imageURLForUser(user)}` !== '' ? (
            <img
              width={42}
              height={42}
              className="mr-3 rounded-circle"
              src={imageURLForUser(user)}
              alt=""
            />
          ) : ('')
          }
          <Media.Body>
            <h5>
              {`${user.first_name} ${user.last_name}`}
              {' '}
            </h5>
            <p>{user.email}</p>
          </Media.Body>
        </Media>
      </td>
      <td>
        <div className="account-type">
          <img src={userType.image} alt={userType.display_name} />
          {' '}
          {userType.display_name}
        </div>
      </td>
      <td>
        <div className="">
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.presence}` === 'true' ? (<img className="app-icon-user-listing" src={presence} title="Presence" alt="Presence" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.phone}` === 'true' ? (<img className="app-icon-user-listing" src={appphone} title="Phone" alt="Phone" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          /* {`${user.apps.call_recording}` == 'true' ? (<img className="app-icon-user-listing" width={24} height={24} src={presence} title="Call Recording"/>) : ('')} */
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.chat}` === 'true' ? (<img className="app-icon-user-listing" src={appchat} title="Chat" alt="Chat" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.call_center_console}` === 'true' ? (<img className="app-icon-user-listing" src={callcenter} title="Call Center" alt="Call Center" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.conference_center}` === 'true' ? (<img className="app-icon-user-listing" src={conferenceCenter} title="Conference Center" alt="Conference Center" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.voice_mail}` === 'true' ? (<img className="app-icon-user-listing" src={apprecord} title="Voice Mail" alt="Voice Mail" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.webphone}` === 'true' ? (<img className="app-icon-user-listing" src={QuickCall} title="Web Phone" alt="Web Phone" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.company_directory}` === 'true' ? (<img className="app-icon-user-listing" src={companyDirectory} title="Company Directory" alt="Company Directory" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.contacts}` === 'true' ? (<img className="app-icon-user-listing" src={contactsImage} title="Contacts" alt="Contacts" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.meeting}` === 'true' ? (<img className="app-icon-user-listing" src={meetingIcon} title="Meeting" alt="Meeting" />) : ('')
          }
          {
          // eslint-disable-next-line max-len
          `${user.apps && user.apps.tasks}` === 'true' ? (<img className="app-icon-user-listing" src={task} title="Tasks" alt="Tasks" />) : ('')
          }
          {
          //  eslint-disable-next-line max-len
          `${user.apps && user.apps.notes}` === 'true' ? (<img className="app-icon-user-listing" src={notes} title="Notes" alt="Notes" />) : ('')
          }
        </div>
      </td>
      {userType.type === 'zendesk'
        ? <td className="dots-menu text-center" />
        : (
          <td className="dots-menu text-center">
            <Dropdown drop="left">
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <img src={DotsMenu} alt="boomea" width={16} />
              </Dropdown.Toggle>
              {
              `${user.email_verified}` === '1' ? (
                <Dropdown.Menu>
                  {menus}
                  <Dropdown.Item
                    href="#/action-4"
                    onClick={(e) => showConfirmModal(e, user.id, 'force_logout')}
                  >
                  Force Logout
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-5"
                    onClick={(e) => showConfirmModal(e, user.id, 'send_passwordreset')}
                  >
                  Send Reset Password
                  </Dropdown.Item>
                </Dropdown.Menu>
              ) : (
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-6"
                    onClick={(e) => showConfirmModal(e, user.id, 'resend_invite')}
                  >
                  Resend Invite
                  </Dropdown.Item>
                </Dropdown.Menu>
              )
             }
            </Dropdown>
          </td>
        )}
    </tr>
  );
};

TeamList.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number]).isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  billingController: PropTypes.func.isRequired,
  handleUserEditAction: PropTypes.func.isRequired,
  handleUserSuspendAction: PropTypes.func.isRequired,
};

export default TeamList;
