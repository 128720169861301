import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';

class DomainForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authentication: false,
      switch1: false,
    };
  }

  submitForm = (e) => {
    e.preventDefault();
    const { postSetting, currentTeam, integrationId } = this.props;
    postSetting(currentTeam.currentTeamId, integrationId, this.state);
  }

  handleChange=(event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { authentication, switch1 } = this.state;
    return (
      <Form onSubmit={this.submitForm} className="integrationForm">
        <div className="form-box">
          <div className="form-body">
            <Row>
              <Col xs={6}>
                <Form.Group controlId="domain">
                  <Form.Label>
                    <span>*</span>
                      Domain
                    {' '}
                  </Form.Label>
                  <div className="form-group form-inline">
                    <Form.Control type="text" name="domain" required onChange={this.handleChange} />
                    &nbsp;.zendesk.com
                  </div>
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>
                    <span>*</span>
                      Email
                    {' '}
                  </Form.Label>
                  <Form.Control type="email" name="username" required onChange={this.handleChange} />
                </Form.Group>
                <Form.Group controlId="password">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked={switch1}
                      onChange={() => { this.setState({ authentication: !switch1, switch1: !switch1 }); }}
                      readOnly
                    />
                    <label className="custom-control-label" htmlFor="customSwitches">
                      {(authentication
                        ? (
                          <div className="off">Disable</div>
                        ) : (
                          <div className="on">Enable</div>
                        )
                      )}
                      <div className="tokenlable">
                        <a
                          // eslint-disable-next-line max-len
                          href="https://develop.zendesk.com/hc/en-us/articles/360001074508-Using-the-API-with-2-factor-authentication-enabled"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                              Token Authentication
                        </a>
                      </div>
                    </label>

                  </div>
                </Form.Group>
                {(!authentication
                  ? (
                    <Form.Group controlId="password">
                      <Form.Label>
                                      Password
                        <span>*</span>
                      </Form.Label>
                      <Form.Control type="password" name="password" required onChange={this.handleChange} />
                    </Form.Group>
                  )
                  : (
                    <Form.Group controlId="token">
                      <Form.Label>
                        <span>*</span>
                                      API Token
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="6"
                        className="text-area"
                        onChange={this.handleChange}
                        name="token"
                      />
                    </Form.Group>
                  )
                )}
              </Col>
            </Row>
          </div>
          <div className="form-footer">
            <Row>
              <Col xs={12}>
                <Button variant="blue" type="submit">
                  SAVE
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    );
  }
}
DomainForm.propTypes = {
  postSetting: PropTypes.func.isRequired,
  integrationId: PropTypes.string.isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};
export default DomainForm;
