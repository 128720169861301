// Copyright 2019, White Label Communications, LLC, All rights reserved.

import fetch from 'cross-fetch';
import { fetchLogin } from './LoginActions';

export const SIGNUP_REQUEST_START = 'SIGNUP_REQUEST_START';
function fetchSignUpRequestStart() {
  return {
    type: SIGNUP_REQUEST_START,
  };
}

export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS';
function fetchSignUpRequestSuccess(json) {
  return {
    type: SIGNUP_REQUEST_SUCCESS,
    data: json,
  };
}

export const SIGNUP_REQUEST_FAILURE = 'SIGNUP_REQUEST_FAILURE';
function fetchSignUpRequestFail(json) {
  return {
    type: SIGNUP_REQUEST_FAILURE,
    error: json.message,
  };
}

export function fetchSignUpRequest(formData) {
  return async (dispatch) => {
    dispatch(fetchSignUpRequestStart());
    const jsonResponse = true;
    const detailobj = {
      team_name: formData.companyName,
      owner_email: formData.adminEmail,
      invitees: formData.teamEmails,
    };
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(detailobj),
      credentials: 'include',
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/v4/signup`,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchSignUpRequestFail(responseJson));
        } else {
          dispatch(fetchSignUpRequestSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchSignUpRequestFail(error));
        } else {
          dispatch(fetchSignUpRequestFail({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const SIGNUP_CONFIRM_START = 'SIGNUP_CONFIRM_START';
function fetchSignUpConfirmStart() {
  return {
    type: SIGNUP_CONFIRM_START,
  };
}

export const SIGNUP_CONFIRM_SUCCESS = 'SIGNUP_CONFIRM_SUCCESS';
function fetchSignUpConfirmSuccess(json) {
  return {
    type: SIGNUP_CONFIRM_SUCCESS,
    data: json,
  };
}

export const SIGNUP_CONFIRM_FAILURE = 'SIGNUP_CONFIRM_FAILURE';
function fetchSignUpConfirmFail(json) {
  return {
    type: SIGNUP_CONFIRM_FAILURE,
    error: json.message,
  };
}

export function fetchSignUpConfirm(token) {
  return async (dispatch) => {
    dispatch(fetchSignUpConfirmStart());
    const jsonResponse = true;
    const detailobj = {
      token,
    };
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(detailobj),
      credentials: 'include',
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/v4/signup_check`,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchSignUpConfirmFail(responseJson));
        } else {
          dispatch(fetchSignUpConfirmSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchSignUpConfirmFail(error));
        } else {
          dispatch(fetchSignUpConfirmFail({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const SIGNUP_FINAL_START = 'SIGNUP_FINAL_START';
function fetchSignUpFinalStart() {
  return {
    type: SIGNUP_FINAL_START,
  };
}


export const SIGNUP_FINAL_SUCCESS = 'SIGNUP_FINAL_SUCCESS';

/* function fetchSignUpFinalSuccess(json) {
  return {
    type: SIGNUP_FINAL_SUCCESS,
    data: json,
  };
} */

export const SIGNUP_FINAL_FAILURE = 'SIGNUP_FINAL_FAILURE';
function fetchSignUpFinalFail(json) {
  return {
    type: SIGNUP_FINAL_FAILURE,
    error: json.message,
  };
}

export function fetchSignUpFinal(formData) {
  return async (dispatch) => {
    let email;
    const detailobjToken = {
      token: formData.token,
    };
    const fetchobj1 = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      // body: '{"token":"' + formData.token + '"}',
      body: JSON.stringify(detailobjToken),
      credentials: 'include',
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/v4/signup_check`,
      fetchobj1,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        email = responseJson.email;
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
      });
    dispatch(fetchSignUpFinalStart());
    const jsonResponse = true;
    const detailobj = {
      token: formData.token,
      first: formData.first,
      last: formData.last,
      password: formData.password,
      password2: formData.password2,
    };
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(detailobj),
      credentials: 'include',
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/v4/signup_final`,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchSignUpFinalFail(responseJson));
        } else {
          dispatch(fetchLogin(email, formData.password));
          // dispatch(fetchSignUpFinalSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchSignUpFinalFail(error));
        } else {
          dispatch(fetchSignUpFinalFail({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}
