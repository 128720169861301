// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import DatePicker from 'rc-calendar/lib/Picker';
import moment from 'moment';

class Picker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverValue: [],
      format: 'MM-DD-YYYY',
      now: moment(),
    };
  }

  onHoverChange = (hoverValue) => {
    this.setState({ hoverValue });
  };

  render() {
    const {
      showValue,
      type,
      onChange,
      disabledDate,
      open,
      onOpenChange,
      value,
      Placeholder,
      className,
    } = this.props;
    const {
      hoverValue,
      now,
      format,
    } = this.state;
    const calendar = (
      <RangeCalendar
        hoverValue={hoverValue}
        onHoverChange={this.onHoverChange}
        type={type}
        defaultValue={now}
        format={format}
        onChange={onChange}
        disabledDate={disabledDate}
      />
    );
    return (
      <DatePicker
        open={open}
        onOpenChange={onOpenChange}
        calendar={calendar}
        value={value}
      >
        {() => (
          <input
            placeholder={Placeholder}
            className={`form-control ${className}`}
            readOnly
            value={(showValue && showValue.format(format)) || ''}
          />
        )}
      </DatePicker>
    );
  }
}

Picker.propTypes = {
  showValue: PropTypes.oneOfType([PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabledDate: PropTypes.func,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  value: PropTypes.arrayOf,
  Placeholder: PropTypes.string,
  className: PropTypes.string,
};

Picker.defaultProps = {
  disabledDate: () => {},
  open: false,
  onOpenChange: () => {},
  value: [],
  Placeholder: '',
  className: '',
};

export default Picker;
