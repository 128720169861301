// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  SIGNUP_CONFIRM_START,
  SIGNUP_CONFIRM_SUCCESS,
  SIGNUP_CONFIRM_FAILURE,
} from 'actions/SignUpActions';

const initialState = {
  status: 0, // 0=logged off(init state), 1=loading/checking, 2=error, 3=success
  error: '', // holds error mess when status=2
  mode: 0, // 0= no mode, 1=admin email already in boomea system, 2= admin email not in boomea system
  email: '', // email returned from server at signup confirm step
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNUP_CONFIRM_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
      };
    case SIGNUP_CONFIRM_SUCCESS:
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
        mode: action.data.mode,
        email: action.data.email,
      };
    case SIGNUP_CONFIRM_FAILURE:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
      };
    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        error: '',
      };
    default:
      return state;
  }
}
