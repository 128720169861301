// Copyright 2019, White Label Communications, LLC, All rights reserved.
import { connect } from 'react-redux';
import { postSetting } from 'actions/IntegrationSettingActions';
import DomainForm from './DomainForm';

const mapStateToProps = (state) => {
  const { integrations } = state.Entities;
  const {
    currentTeam,
  } = state.Ui;
  return { list: integrations.list, currentTeam };
};

const mapDispatchToProps = {
  postSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainForm);
