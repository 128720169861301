// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  FETCH_VOICE_RETENTION_SUCCESS,
  VOICE_RETENTION_POST_SUCCESS,
} from 'actions/VoiceRetentionActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_VOICE_RETENTION_SUCCESS:
    case VOICE_RETENTION_POST_SUCCESS:
      // console.log('messageRetention: ', action.data); // eslint-disable-line no-console
      return action.data; // this value passed in on action
    case FETCH_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}
