// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Select from 'react-select';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import Settings from 'images/settings.svg';

const tasksRetentionOptions = [
  {
    label: 'Keep completed tasks forever',
    value: 0,
  },
  {
    label: 'Keep completed tasks, but only for a set duration',
    value: 1,
  },
];
const tasksRetentionPeriodOptions = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Months',
    value: 'm',
  },
  {
    label: 'Years',
    value: 'y',
  },
];

class TasksRetention extends React.PureComponent {
  constructor(props) {
    super(props);
    const { tasksRetentionData } = this.props;
    // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
    let tasksCompletedPeriodObj = null;
    let tasksCompletedValueObj = null;
    let tasksCompletedCount = 0;
    if (tasksRetentionData.completed_tasks) {
      tasksCompletedValueObj = tasksRetentionOptions
        .find((option) => option.value === tasksRetentionData.completed_tasks.value);
      tasksCompletedPeriodObj = tasksRetentionPeriodOptions
        .find((option) => option.value === tasksRetentionData.completed_tasks.duration.period);
      tasksCompletedCount = tasksRetentionData.completed_tasks.duration.count;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      tasksRetentionData,
      tasksRetention: {
        completed_tasks: {
          value: tasksCompletedValueObj,
          duration: {
            count: tasksCompletedCount,
            period: tasksCompletedPeriodObj,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (!equal(props.tasksRetentionData, state.tasksRetentionData)) {
      const { tasksRetentionData } = props;
      let tasksCompletedPeriodObj = null;
      let tasksCompletedValueObj = null;
      let tasksCompletedCount = 0;
      if (tasksRetentionData.completed_tasks) {
        tasksCompletedValueObj = tasksRetentionOptions
          .find((option) => option.value === tasksRetentionData.completed_tasks.value);
        tasksCompletedPeriodObj = tasksRetentionPeriodOptions
          .find((option) => option.value === tasksRetentionData.completed_tasks.duration.period);
        tasksCompletedCount = tasksRetentionData.completed_tasks.duration.count;
      }
      const newState = {
        tasksRetentionData,
        tasksRetention: {
          completed_tasks: {
            value: tasksCompletedValueObj,
            duration: {
              count: tasksCompletedCount,
              period: tasksCompletedPeriodObj,
            },
          },
        },
      };
      // console.log('newState: ', newState);
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const {
      fetchTasksRetention,
      currentTeam,
    } = this.props;
    fetchTasksRetention(currentTeam.currentTeamId); // get initial tasks retention values
  }

  componentDidUpdate(prevProps) {
    const { uiTasksRetention } = this.props;
    if (prevProps.uiTasksRetention.post.status === 1 && uiTasksRetention.post.status === 2) {
      this.setAlerts('', uiTasksRetention.post.error);
    }
    if (prevProps.uiTasksRetention.post.status === 1 && uiTasksRetention.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  changeValue = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const tasksName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        tasksRetention: {
          ...prevState.tasksRetention,
          [tasksName]: {
            ...prevState.tasksRetention[tasksName],
            [name]: selectedValue,
          },
        },
      }),
    );
  }

  changePeriod = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const tasksName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        tasksRetention: {
          ...prevState.tasksRetention,
          [tasksName]: {
            ...prevState.tasksRetention[tasksName],
            duration: {
              ...prevState.tasksRetention[tasksName].duration,
              [name]: selectedValue,
            },
          },
        },
      }),
    );
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    const splittedNameArr = name.split('-');
    const tasksName = splittedNameArr[0];
    const inputName = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        tasksRetention: {
          ...prevState.tasksRetention,
          [tasksName]: {
            ...prevState.tasksRetention[tasksName],
            duration: {
              ...prevState.tasksRetention[tasksName].duration,
              [inputName]: value,
            },
          },
        },
      }),
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      postTasksRetention,
      currentTeam,
    } = this.props;
    const { tasksRetention } = this.state;
    const tasksRetentionSaveObj = { completed_tasks: {} };
    tasksRetentionSaveObj.completed_tasks.value = tasksRetention.completed_tasks.value.value;
    tasksRetentionSaveObj.completed_tasks.duration = {
      count: parseInt(tasksRetention.completed_tasks.duration.count, 10),
      period: tasksRetention.completed_tasks.duration.period.value,
    };
    // console.log('submit: ', tasksRetention); // eslint-disable-line no-console
    postTasksRetention(tasksRetentionSaveObj, currentTeam.currentTeamId); // save file retention values
    // const { tasksRetention } = this.state;
  }

  render() {
    const {
      tasksRetention,
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 2, span: 8 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>TASKS RETENTION</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area retention">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Form onSubmit={this.submitForm}>
                        <Container fluid>
                          <Row>
                            <Col md={7}>
                              <Form.Group controlId="retention-select-tasks-completed">
                                <Form.Label>Completed Tasks:</Form.Label>
                                <Select
                                  options={tasksRetentionOptions}
                                  value={
                                    (tasksRetention
                                      && tasksRetention.completed_tasks
                                      && tasksRetention.completed_tasks.value
                                    )
                                      ? tasksRetention.completed_tasks.value
                                      : null
                                  }
                                  className="select-container"
                                  name="completed_tasks-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(tasksRetention
                                && tasksRetention.completed_tasks
                                && tasksRetention.completed_tasks.value
                                && tasksRetention.completed_tasks.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (tasksRetention
                                          && tasksRetention.completed_tasks
                                          && tasksRetention.completed_tasks.duration
                                          && tasksRetention.completed_tasks.duration.count
                                        )
                                          ? tasksRetention.completed_tasks.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="completed_tasks-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={tasksRetentionPeriodOptions}
                                      className="select-container days-type"
                                      value={
                                        (tasksRetention
                                          && tasksRetention.completed_tasks
                                          && tasksRetention.completed_tasks.duration
                                          && tasksRetention.completed_tasks.duration.period
                                        )
                                          ? tasksRetention.completed_tasks.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="completed_tasks-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              )
                              : null }
                          </Row>

                          <div className="line-sep" />

                          <Row>
                            <Col md={12}>
                              <Button variant="blue" type="submit">SAVE CHANGES</Button>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

TasksRetention.propTypes = {
  fetchTasksRetention: PropTypes.func.isRequired,
  postTasksRetention: PropTypes.func.isRequired,
  tasksRetentionData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiTasksRetention: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

TasksRetention.defaultProps = {
  tasksRetentionData: {},
};

export default TasksRetention;
