// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import post from './Post';
import put from './Zendesk/Trigger/Put';
import deleteIntegration from './Zendesk/Trigger/DeleteIntegration';

export default combineReducers({
  post,
  put,
  deleteIntegration,
});
