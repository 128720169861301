// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchSignUpConfirm } from 'actions/SignUpActions';
import SignUpConfirm from './SignUpConfirm';

function mapStateToProps(state) {
  const { signUp } = state.Ui;
  return {
    signUpConfirm: signUp.signUpConfirm,
  };
}

const mapDispatchToProps = {
  fetchSignUpConfirm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpConfirm);
