// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { usersStatus } from 'actions/UsersActions';
import { setCurrentTeamId } from 'actions/TeamActions';
import DashBoardHeader from './Header';

const mapStateToProps = (state) => (
  {
    login: state.Ui.login,
    usersCompleteList: state.Entities.usersCompleteList,
    teams: state.Entities.teams.data,
    currentTeam: state.Ui.currentTeam,
  }
);

const mapDispatchToProps = {
  usersStatus,
  setCurrentTeamId,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardHeader);
