// Copyright 2019, White Label Communications, LLC, All rights reserved.

import fetch from 'cross-fetch';
import { countTeamAdminInTeams } from 'components/Common/Utils';
import { fetchTeams, setCurrentTeamId } from './TeamActions';

export const TEAM_MODAL_SUCCESS = 'TEAM_MODAL_SUCCESS';
export function setTeamModal(status) {
  return {
    type: TEAM_MODAL_SUCCESS,
    data: { status },
  };
}

export const FETCH_LOGOUT_START = 'FETCH_LOGOUT_START';
function fetchLogoutStart() {
  return {
    type: FETCH_LOGOUT_START,
  };
}

export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
function fetchLogoutSuccess(json) {
  return {
    type: FETCH_LOGOUT_SUCCESS,
    // OPEN, not sure what this is going to contain
    json,
  };
}

export const FETCH_LOGOUT_FAIL = 'FETCH_LOGOUT_FAIL';
function fetchLogoutFail(json) {
  return {
    type: FETCH_LOGOUT_FAIL,
    // by convention, any kind of api failure will put the message in message
    // error: json.message
    // no simple way to fail logout
    // OPEN, not sure what this is going to contain
    json,
  };
}

export function fetchLogout() {
  return (dispatch) => {
    // invalidate the cookie
    // console.log('fetchLogout');
    // console.log(document.cookie);
    const hn = window.location.hostname.toLowerCase();
    // console.log(hn);
    // add longer names first
    if (hn.substring(hn.length - 14) === 'boomealabs.net') {
      // dev3
      // console.log('clear dev3 cookies');
      document.cookie = 'MMUSERID=;domain=.boomealabs.net;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'MMAUTHTOKEN=;domain=.boomealabs.net;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    } else if (hn.substring(hn.length - 11) === 'wlclabs.net') {
      // console.log('clear dev1 cookies');
      document.cookie = 'MMUSERID=;domain=.wlclabs.net;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'MMAUTHTOKEN=;domain=.wlclabs.net;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    } else if (hn.substring(hn.length - 10) === 'boomea.com') {
      // prod
      // console.log('clear prod cookies');
      document.cookie = 'MMUSERID=;domain=.boomea.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'MMAUTHTOKEN=;domain=.boomea.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    } else {
      // console.log('clear local dev cookies');
      document.cookie = 'MMUSERID=;domain=.ex.local;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      // for now, need to disable MMAUTHTOKEN, since it is no longer httponly
      document.cookie = 'MMAUTHTOKEN=;domain=.ex.local;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      // console.log(document.cookie);
    }

    dispatch(fetchLogoutStart());
    const fetchobj = {
      method: 'POST',
      credentials: 'include',
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v4/users/logout`,
      fetchobj,
    )
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            dispatch(fetchLogoutSuccess(json));
          } else {
            dispatch(fetchLogoutFail(json));
          }
        });
      })
      .catch((error) => {
        // OPEN how to handle error
        console.log(error); // eslint-disable-line no-console
      });
  };
}

export const FETCH_LOGIN_START = 'FETCH_LOGIN_START';
function fetchLoginStart() {
  return {
    type: FETCH_LOGIN_START,
  };
}

export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
function fetchLoginSuccess(json) {
  return {
    type: FETCH_LOGIN_SUCCESS,
    data: json,
    lastLogin: Date.now(),
  };
}

export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';
function fetchLoginFail(json) {
  return {
    type: FETCH_LOGIN_FAIL,
    error: json.message,
  };
}

export function fetchLogin(username, password) {
  return async (dispatch) => {
    dispatch(fetchLoginStart());
    let fetchLoginData = false;
    let jsonResponse = false;
    const detailobj = {
      login_id: username,
      password,
      device_id: '',
      token: '',
    };
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(detailobj),
    };
    // the next line automatically converts to:
    //   http://localhost:3000/api/v4/users/login
    // return fetch('/api/v4/users/login', fetchobj)

    // the line below converts to 2 calls
    //   http://localhost:8080/api/v4/users/login
    // return fetch('http://localhost:8080/api/v4/users/login', fetchobj)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v4/users/login`,
        fetchobj,
      );
      jsonResponse = await response.json();
      if (response.ok) {
        fetchLoginData = jsonResponse;
      } else {
        dispatch(fetchLoginFail(jsonResponse));
        return { error: { message: 'unexpected json returned' } };
      }
    } catch (error) {
      // OPEN how to handle error
      console.log(error); // eslint-disable-line no-console
      return { error };
    }
    if (fetchLoginData) {
      if (fetchLoginData.id) { // if user id returned in response get other login user details
        const teams = await dispatch(fetchTeams(fetchLoginData.id));
        if (teams && teams.data && teams.data.teamMembersResponse) {
          const teamAdmins = countTeamAdminInTeams(teams.data.teamMembersResponse);
          if (teamAdmins.length === 0) {
            // if user is not team_admin in any of the team, show him 403 permission error and logs him out
            // dispatch(fetchLogout()); // commenting this because it will clear cookies which will log user out of frontend
            dispatch(fetchLoginFail({ message: 'permission_error' }));
          }
          // end team length === 0
          if (teamAdmins.length === 1) {
            // if user is team_admin on only one team, select that team as default team and dispatch fetchLoginSuccess
            dispatch(setCurrentTeamId(teamAdmins[0].team_id));
            dispatch(fetchLoginSuccess(jsonResponse));
          }
          if (teamAdmins.length > 1) {
            // dispatch fetchLoginSuccess and don't set currentTeam as User will be shown teams select modal
            dispatch(setCurrentTeamId(teamAdmins[0].team_id));
            dispatch(fetchLoginSuccess(jsonResponse));
          }
        }
      }
    }
    return { data: jsonResponse };
  };
}

export const FETCH_COOKIE_LOGIN_START = 'FETCH_COOKIE_LOGIN_START';
function fetchCookieLoginStart() {
  return {
    type: FETCH_COOKIE_LOGIN_START,
  };
}

export const FETCH_COOKIE_LOGIN_SUCCESS = 'FETCH_COOKIE_LOGIN_SUCCESS';
function fetchCookieLoginSuccess(json) {
  return {
    type: FETCH_COOKIE_LOGIN_SUCCESS,
    data: json,
    lastLogin: Date.now(),
  };
}

export const FETCH_COOKIE_LOGIN_FAIL = 'FETCH_COOKIE_LOGIN_FAIL';
function fetchCookieLoginFail(json) {
  return {
    type: FETCH_COOKIE_LOGIN_FAIL,
    error: json.message,
  };
}

export function fetchCookieLogin() {
  return async (dispatch) => {
    let fetchLoginData = false;
    let jsonResponse = false;
    dispatch(fetchCookieLoginStart());
    const fetchobj = {
      method: 'GET',
      credentials: 'include',
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v4/users/cookie_login`,
        fetchobj,
      );
      jsonResponse = await response.json();
      if (response.ok) {
        fetchLoginData = jsonResponse;
      } else {
        dispatch(fetchCookieLoginFail(jsonResponse));
        return { error: { message: 'unexpected json returned' } };
      }
    } catch (error) {
      // OPEN how to handle error
      console.log(error); // eslint-disable-line no-console
      return { error };
    }
    if (fetchLoginData) {
      if (fetchLoginData.id) { // if user id returned in response get other login user details
        const teams = await dispatch(fetchTeams(fetchLoginData.id));
        if (teams && teams.data && teams.data.teamMembersResponse) {
          const teamAdmins = countTeamAdminInTeams(teams.data.teamMembersResponse);
          if (teamAdmins.length === 0) {
            // if user is not team_admin in any of the team, show him 403 permission error and logs him out
            // dispatch(fetchLogout()); // // commenting this because it will clear cookies which will log user out of frontend
            dispatch(fetchCookieLoginFail({ message: 'permission_error' }));
          }
          // end team length === 0
          if (teamAdmins.length === 1) {
            // if user is team_admin on only one team, select that team as default team and dispatch fetchLoginSuccess
            dispatch(setCurrentTeamId(teamAdmins[0].team_id));
            dispatch(fetchCookieLoginSuccess(jsonResponse));
          }
          if (teamAdmins.length > 1) {
            // dispatch fetchLoginSuccess and don't set currentTeam as User will be shown teams select modal
            dispatch(setCurrentTeamId(teamAdmins[0].team_id));
            dispatch(fetchCookieLoginSuccess(jsonResponse));
          }
        }
      }
    }
    /* return fetch(
      `${process.env.REACT_APP_API_URL}/api/v4/users/cookie_login`,
      fetchobj,
    )
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            dispatch(fetchCookieLoginSuccess(json));
          } else {
            dispatch(fetchCookieLoginFail(json));
          }
        });
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
      }); */
    return { data: jsonResponse };
  };
}
