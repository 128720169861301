// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import SignUpFinal from 'components/SignUp/SignUpFinal';
import WhiteLogo from 'images/boomea-white-logo.svg';

class SignUpConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    const {
      location: {
        search,
      },
      fetchSignUpConfirm,
    } = this.props;
    const url = new URLSearchParams(search);
    const token = url.get('token');
    if (token) {
      this.setState({ token });
      fetchSignUpConfirm(token);
    }
  }

  render() {
    const { signUpConfirm } = this.props;
    const { token } = this.state;
    let message = 'Something went wrong. Please try again later or contact site administrator.';
    if (!token) {
      return null; // return nothing if token not present in url
    }
    if (signUpConfirm.status && signUpConfirm.status === 1) {
      message = ('Please Wait. Authenticating...'); // this means signup_check request in progress
    }
    if (signUpConfirm.status && signUpConfirm.status === 2) {
      message = (`Something went Wrong. Error: ${signUpConfirm.error}`); // this means signup_check failed show user error message
    }
    if (signUpConfirm.status && signUpConfirm.status === 3 && signUpConfirm.mode === '2') {
      return (
        <SignUpFinal
          token={token}
        />
      );
    }
    if (signUpConfirm.status && signUpConfirm.status === 3 && signUpConfirm.mode === '1') {
      return (
        <Redirect
          to="/login"
          loginMessage="Email Verified Successfully. You can now login into your boomea Admin account"
        />
      );
    }
    return (
      <>
        <div className="login" id="login-section">
          <div className="clearfix">
            <div className="col-6 mx-auto">
              <img src={WhiteLogo} className="logo" alt="boomea" />
              <div className="form-area step-five">
                <h1>{message}</h1>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="clearfix">
              <div className="col-8 mx-auto">
                <p>&copy; 2019 Boomea. All rights reserved </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SignUpConfirm.propTypes = {
  fetchSignUpConfirm: PropTypes.func.isRequired,
  signUpConfirm: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  location: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

SignUpConfirm.defaultProps = {
  signUpConfirm: {},
};


export default SignUpConfirm;
