import React from 'react';
import { Link } from 'react-router-dom';

const StepSix = () => (
  <>
    <div className="form-area step-four">
      <h1>You&apos;re all done, Great Job! </h1>
      <div className="separator" />
      <h2>Check your email for the magic link to sign into your</h2>
      <h3>New BOOMEA Team!</h3>

      <Link to="/users/users">
        {' '}
        <button type="button" className="end-button">
            OK
        </button>
        {' '}
      </Link>
    </div>
  </>
);

export default StepSix;
