// Copyright 2019, White Label Communications, LLC, All rights reserved.

// import fetch from 'cross-fetch';

export const FETCH_FILE_RETENTION_START = 'FETCH_FILE_RETENTION_START';
function fetchFileRetentionStart() {
  return {
    type: FETCH_FILE_RETENTION_START,
  };
}

export const FETCH_FILE_RETENTION_SUCCESS = 'FETCH_FILE_RETENTION_SUCCESS';
function fetchFileRetentionSuccess(json) {
  return {
    type: FETCH_FILE_RETENTION_SUCCESS,
    data: json,
  };
}

export const FETCH_FILE_RETENTION_ERROR = 'FETCH_FILE_RETENTION_ERROR';
function fetchFileRetentionError(json) {
  return {
    type: FETCH_FILE_RETENTION_ERROR,
    error: json.message,
  };
}

export function fetchFileRetention(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fetchFileRetentionStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/file_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchFileRetentionError(responseJson));
        } else {
          dispatch(fetchFileRetentionSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchFileRetentionError(error));
        } else {
          dispatch(fetchFileRetentionError({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const FILE_RETENTION_POST_START = 'FILE_RETENTION_POST_START';
function fileRetentionPostStart() {
  return {
    type: FILE_RETENTION_POST_START,
  };
}

export const FILE_RETENTION_POST_SUCCESS = 'FILE_RETENTION_POST_SUCCESS';
function fileRetentionPostSuccess(response) {
  return {
    type: FILE_RETENTION_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const FILE_RETENTION_POST_ERROR = 'FILE_RETENTION_POST_ERROR';
function fileRetentionPostError(error) {
  const ret = {
    type: FILE_RETENTION_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postFileRetention(teamId, formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fileRetentionPostStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/file_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fileRetentionPostError(responseJson));
        } else {
          dispatch(fileRetentionPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(fileRetentionPostError(error));
        } else {
          dispatch(fileRetentionPostError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}
