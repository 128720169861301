// Copyright 2019, White Label Communications, LLC, All rights reserved.

// import fetch from 'cross-fetch';

export const FETCH_ALL_CHANNELS_START = 'FETCH_ALL_CHANNELS_START';
function fetchAllChannelsStart() {
  return {
    type: FETCH_ALL_CHANNELS_START,
  };
}

export const FETCH_ALL_CHANNELS_SUCCESS = 'FETCH_ALL_CHANNELS_SUCCESS';
function fetchAllChannelsSuccess(json) {
  return {
    type: FETCH_ALL_CHANNELS_SUCCESS,
    data: json,
  };
}

export const FETCH_ALL_CHANNELS_ERROR = 'FETCH_ALL_CHANNELS_ERROR';
function fetchAllChannelsError(json) {
  return {
    type: FETCH_ALL_CHANNELS_ERROR,
    error: json.message,
  };
}

export function fetchAllChannels(teamId, page, search) {
  if (search === '') {
    return async (dispatch) => {
      let jsonResponse = false;
      dispatch(fetchAllChannelsStart());
      const fetchobj = {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      };
      const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/channels?page=${page}&per_page=100`;
      await fetch(
        url,
        fetchobj,
      )
        .then((response) => (response.json()))
        .then((responseJson) => {
          if (responseJson.status_code && responseJson.status_code !== 200) {
            dispatch(fetchAllChannelsError(responseJson));
          } else {
            dispatch(fetchAllChannelsSuccess(responseJson));
            // console.log(responseJson, ': responseJson'); // eslint-disable-line no-console
            jsonResponse = responseJson;
          }
        })
        .catch((error) => {
          console.log(error, ': error'); // eslint-disable-line no-console
          if (error.message) {
            dispatch(fetchAllChannelsError(error));
          } else {
            dispatch(fetchAllChannelsError({ message: 'API error' }));
          }
        });

      return { data: jsonResponse };
    };
  //  eslint-disable-next-line no-else-return
  } else {
    return async (dispatch) => {
      let jsonResponse = false;
      dispatch(fetchAllChannelsStart());
      const fetchobj = {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ term: search }),
      };
      const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/channels/search`;
      await fetch(
        url,
        fetchobj,
      )
        .then((response) => (response.json()))
        .then((responseJson) => {
          if (responseJson.status_code && responseJson.status_code !== 200) {
            dispatch(fetchAllChannelsError(responseJson));
          } else {
            dispatch(fetchAllChannelsSuccess(responseJson));
            // console.log(responseJson, ': responseJson'); // eslint-disable-line no-console
            jsonResponse = responseJson;
          }
        })
        .catch((error) => {
          console.log(error, ': error'); // eslint-disable-line no-console
          if (error.message) {
            dispatch(fetchAllChannelsError(error));
          } else {
            dispatch(fetchAllChannelsError({ message: 'API error' }));
          }
        });
      return { data: jsonResponse };
    };
  }
}

export const FETCH_DEFAULT_CHANNELS_START = 'FETCH_DEFAULT_CHANNELS_START';
function fetchDefaultChannelsStart() {
  return {
    type: FETCH_DEFAULT_CHANNELS_START,
  };
}

export const FETCH_DEFAULT_CHANNELS_SUCCESS = 'FETCH_DEFAULT_CHANNELS_SUCCESS';
function fetchDefaultChannelsSuccess(json) {
  return {
    type: FETCH_DEFAULT_CHANNELS_SUCCESS,
    data: json,
  };
}

export const FETCH_DEFAULT_CHANNELS_ERROR = 'FETCH_DEFAULT_CHANNELS_ERROR';
function fetchDefaultChannelsError(json) {
  return {
    type: FETCH_DEFAULT_CHANNELS_ERROR,
    error: json.message,
  };
}

export function fetchDefaultChannels(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fetchDefaultChannelsStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/channel_default`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchDefaultChannelsError(responseJson));
        } else {
          dispatch(fetchDefaultChannelsSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchDefaultChannelsError(error));
        } else {
          dispatch(fetchDefaultChannelsError({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const DEFAULT_CHANNELS_POST_START = 'DEFAULT_CHANNELS_POST_START';
function defaultChannelsPostStart() {
  return {
    type: DEFAULT_CHANNELS_POST_START,
  };
}

export const DEFAULT_CHANNELS_POST_SUCCESS = 'DEFAULT_CHANNELS_POST_SUCCESS';
function defaultChannelsPostSuccess(response) {
  return {
    type: DEFAULT_CHANNELS_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const DEFAULT_CHANNELS_POST_ERROR = 'DEFAULT_CHANNELS_POST_ERROR';
function defaultChannelsPostError(error) {
  const ret = {
    type: DEFAULT_CHANNELS_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postDefaultChannels(formData, teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(defaultChannelsPostStart());
    // console.log('defaultChannels2: ', formData); // eslint-disable-line no-console
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/channel_default`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(defaultChannelsPostError(responseJson));
        } else {
          dispatch(defaultChannelsPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(defaultChannelsPostError(error));
        } else {
          dispatch(defaultChannelsPostError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}
