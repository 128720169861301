// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  FETCH_FILE_RETENTION_SUCCESS,
  FILE_RETENTION_POST_SUCCESS,
} from 'actions/FileRetentionActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FILE_RETENTION_SUCCESS:
    case FILE_RETENTION_POST_SUCCESS:
      return action.data; // this value passed in on action
    case FETCH_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}
