// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGIN_START,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAIL,
  FETCH_COOKIE_LOGIN_START,
  FETCH_COOKIE_LOGIN_SUCCESS,
  FETCH_COOKIE_LOGIN_FAIL,
  FETCH_LOGOUT_START,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGOUT_FAIL,
  TEAM_MODAL_SUCCESS,
} from '../../../actions/LoginActions';

const initialState = {
  status: 0, // 0=logged off(init state), 1=loading/checking, 2=error, 3=logged on and auth
  error: '', // holds error mess when status=2
  loggedinUserId: '', // holds logged in user data when status = 3
  // email: '', // holds email when status=3
  // first: '',
  // last: '',
  lastLogin: 0,
  teamModalShown: false, // this is added to determine if team modal already shown or not
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LOGIN_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
        // email: '',
        // first: '',
        // last: '',
        loggedinUserId: '', // empty the logged in user data
        lastLogin: 0,
      };
    case FETCH_LOGIN_SUCCESS:
      // console.log('login: ', action.data); // eslint-disable-line no-console
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
        loggedinUserId: action.data.id, // store the logged in User Id
        // first: action.user.first_name,
        // last: action.user.last_name,
        lastLogin: action.lastLogin, // this value passed in on action
        teamModalShown: false,
      };
    case TEAM_MODAL_SUCCESS:
      // console.log('login: ', action.data); // eslint-disable-line no-console
      return {
        ...state,
        teamModalShown: action.data.status,
      };
    case FETCH_LOGIN_FAIL:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
        loggedinUserId: '', // empty the logged in user data
        // for status =2, not worried about email,etc, but clear them just to be neat
        // email: '',
        // first: '',
        // last: '',
        lastLogin: 0,
      };
    case FETCH_COOKIE_LOGIN_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
        loggedinUserId: '',
        // email: '',
        // first: '',
        // last: '',
        lastLogin: 0,
      };
    case FETCH_COOKIE_LOGIN_SUCCESS:
      // console.log('cookie: ', action.data); // eslint-disable-line no-console
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
        loggedinUserId: action.data.id,
        // email: action.user.email,
        // first: action.user.first_name,
        // last: action.user.last_name,
        teamModalShown: false,
      };
    case FETCH_COOKIE_LOGIN_FAIL:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
        loggedinUserId: '',
        // for status =2, not worried about email,etc, but clear them just to be neat
        // email: '',
        // first: '',
        // last: '',
        lastLogin: 0,
      };
    case FETCH_LOGOUT_START:
      // even though just starting, log user out with status=0
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        // error and email,etc don't matter when status=0
        // but just to be neat reset these values back to init state
        error: '',
        loggedinUserId: '',
        // email: '',
        // first: '',
        // last: '',
        lastLogin: 0,
      };
    case FETCH_LOGOUT_SUCCESS:
      // even though start does the same thing, log user out with status=0
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        // error and email,etc don't matter when status=0
        // but just to be neat reset these values back to init state
        error: '',
        loggedinUserId: '',
        // email: '',
        // first: '',
        // last: '',
        lastLogin: 0,
        teamModalShown: false,
      };
    case FETCH_LOGOUT_FAIL:
      // even though server does not send anything other than 200, log user out with status=0
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        // error and email,etc don't matter when status=0
        // but just to be neat reset these values back to init state
        error: '',
        loggedinUserId: '',
        // email: '',
        // first: '',
        // last: '',
        lastLogin: 0,
      };
    default:
      return state;
  }
}
