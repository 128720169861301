// Copyright 2019, White Label Communications, LLC, All rights reserved.

// import fetch from 'cross-fetch';

export const FETCH_NOTES_RETENTION_START = 'FETCH_NOTES_RETENTION_START';
function fetchNotesRetentionStart() {
  return {
    type: FETCH_NOTES_RETENTION_START,
  };
}

export const FETCH_NOTES_RETENTION_SUCCESS = 'FETCH_NOTES_RETENTION_SUCCESS';
function fetchNotesRetentionSuccess(json) {
  return {
    type: FETCH_NOTES_RETENTION_SUCCESS,
    data: json,
  };
}

export const FETCH_NOTES_RETENTION_ERROR = 'FETCH_NOTES_RETENTION_ERROR';
function fetchNotesRetentionError(json) {
  return {
    type: FETCH_NOTES_RETENTION_ERROR,
    error: json.message,
  };
}

export function fetchNotesRetention(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fetchNotesRetentionStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/note_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchNotesRetentionError(responseJson));
        } else {
          dispatch(fetchNotesRetentionSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchNotesRetentionError(error));
        } else {
          dispatch(fetchNotesRetentionError({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const NOTES_RETENTION_POST_START = 'NOTES_RETENTION_POST_START';
function notesRetentionPostStart() {
  return {
    type: NOTES_RETENTION_POST_START,
  };
}

export const NOTES_RETENTION_POST_SUCCESS = 'NOTES_RETENTION_POST_SUCCESS';
function notesRetentionPostSuccess(response) {
  return {
    type: NOTES_RETENTION_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const NOTES_RETENTION_POST_ERROR = 'NOTES_RETENTION_POST_ERROR';
function notesRetentionPostError(error) {
  const ret = {
    type: NOTES_RETENTION_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postNotesRetention(formData, teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(notesRetentionPostStart());
    // console.log('notesRetention2: ', formData); // eslint-disable-line no-console
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/note_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(notesRetentionPostError(responseJson));
        } else {
          dispatch(notesRetentionPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(notesRetentionPostError(error));
        } else {
          dispatch(notesRetentionPostError({ message: 'API error' }));
        }
      });
    dispatch(notesRetentionPostSuccess(formData));
    return { data: jsonResponse };
  };
}
