// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchLogout } from 'actions/LoginActions';
import PageLogout from './PageLogout';

// const mapStateToProps = (state) => (
//   {
//     login: state.ui.login,
//   }
// );

const mapDispatchToProps = {
  fetchLogout,
};

export default connect(null, mapDispatchToProps)(PageLogout);
