// uclabs

import React from 'react';
import LeftArrow from 'images/left-arrow.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import FormError from 'components/Common/FormError';

class StepFive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { nextStep, signUpRequest } = this.props;
    if (prevProps.signUpRequest.status === 1 && signUpRequest.status === 3) {
      nextStep(); // if signup Request started and got success then show user final screen/success message
    }
    if (prevProps.signUpRequest.status === 1 && signUpRequest.status === 2) {
      this.updateServerError(signUpRequest.error);
    }
  };

  updateServerError = (message) => {
    this.setState({ serverError: message }); // this is added just to avoid eslint error of cannot use setState inside componentDidUpdate method
  };

  render() {
    const {
      values,
      previousStep,
      handleSubmit,
      signUpRequest,
    } = this.props;
    const { serverError } = this.state;
    return (
      <>
        <div className="form-area step-five">
          {signUpRequest.status === 1 ? (
            'Sending Request...'
          ) : (
            <>
              <FormError error={serverError} />
              <h1>Invited Team mates</h1>
              <PerfectScrollbar className="scroll-area">
                <ul>
                  {values.teamEmails.map((element) => (
                    <li key={element}>{element}</li>
                  ))}
                </ul>
              </PerfectScrollbar>
              <button
                type="button"
                className="back-button"
                onClick={previousStep}
              >
                <img src={LeftArrow} alt="boomea" />
                {' '}
              </button>
              <button
                type="button"
                className="confirm-button"
                onClick={handleSubmit}
              >
                CONFIRM
                {' '}
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}

StepFive.propTypes = {
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
  signUpRequest: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};

StepFive.defaultProps = {
  previousStep: () => {},
  nextStep: () => {},
};

export default StepFive;
