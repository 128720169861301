import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { Container, Row, Col } from 'react-bootstrap';
import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import BillingIcon from 'images/money.svg';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import DomainForm from './DomainForm';


class ManageSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successAlert: '',
      errorAlert: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { integration, history } = this.props;
    if (prevProps.integration.post.status === 1 && integration.post.status === 2) {
      this.setAlerts('', integration.post.error);
    }
    if (prevProps.integration.post.status === 1 && integration.post.status === 3) {
      history.push('/dashboard/integrations');
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  render() {
    const { match } = this.props;
    const { integrationId } = match.params;
    const {
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="billing">
            <div className="title-section">
              <h2>
                <img src={BillingIcon} alt="boomea" />
                 Save integration settings
              </h2>
            </div>
            <div className="manage-users-table">
              <div className="billing-header">
                <Container fluid>
                  <Row>
                    <Col md={12}>Save integration settings</Col>
                  </Row>
                </Container>
              </div>
              <div className="manage-area">
                <Container fluid>
                  <ErrorAlert
                    error={errorAlert}
                    onAlertDismissed={this.onAlertDismissed}
                    alertType="errorAlert"
                  />
                  <SuccessAlert
                    success={successAlert}
                    onAlertDismissed={this.onAlertDismissed}
                    alertType="successAlert"
                  />
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col xs={12}>
                          <h3>Integration settings detail</h3>
                        </Col>
                      </Row>
                      <DomainForm integrationId={integrationId} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

ManageSettings.propTypes = {
  match: PropTypes.objectOf(
    PropTypes.oneOf([
      PropTypes.string,
    ]),
  ).isRequired,
  history: PropTypes.objectOf().isRequired,
  integration: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

export default ManageSettings;
