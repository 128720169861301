// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';

// export default class DashBoard extends React.Component {
//   render() {
//     return (
//       <div className="warapper">
//         <div className="row">
//           <div className="col-12">
//             {this.props.children}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

const DashBoard = ({ children }) => (
  <div className="warapper">
    <div className="row">
      <div className="col-12">
        {children}
      </div>
    </div>
  </div>
);

DashBoard.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DashBoard;
