// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  USERS_LIST_START,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
} from 'actions/UsersActions';
import { ConvertArrayOfObjectsWithIdToArrayOfIds } from 'reducers/Helpers';

const initialState = {
  status: 0, // 0=logged off(init state), 1=loading/checking, 2=error, 3=logged on and auth
  error: '', // holds error mess when status=2
  records: [], // to hold the companyDirectoryData,
  lastKey: '',
  filters: { search: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERS_LIST_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
        lastKey: '',
      };
    case USERS_LIST_SUCCESS:
      if (action.data.new_data) {
        return {
          ...state,
          status: 3, // this status will turn off isLoading
          error: '', // error not needed for status=3, but ok to blank it out
          records: ConvertArrayOfObjectsWithIdToArrayOfIds(action.data.records), // this value passed in on action
          lastKey: action.data.last_key,
          filters: action.data.filters,
        };
      }
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
        records: [...state.records, ...ConvertArrayOfObjectsWithIdToArrayOfIds(action.data.records)], // this value passed in on action
        lastKey: action.data.last_key,
        filters: action.data.filters,
      };
    case USERS_LIST_ERROR:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
        lastKey: '',
      };
    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        // error and email,etc don't matter when status=0
        // but just to be neat reset these values back to init state
        error: '',
        records: [], // empty the records when user logs out
        lastKey: '',
      };
    default:
      return state;
  }
}
