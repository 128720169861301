// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import {
  fetchDefaultChannels,
  postDefaultChannels,
  fetchAllChannels,
} from 'actions/DefaultChannelsActions';
import DefaultChannels from './DefaultChannels';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const {
    currentTeam,
    uiTeamSettings,
  } = state.Ui;
  const defaultChannelsData = (
    teamSettings
    && teamSettings.defaultChannels
    && teamSettings.defaultChannels.default_channels
    && teamSettings.defaultChannels.default_channels.length
  ) ? (teamSettings.defaultChannels.default_channels)
      .map((channel) => ({ value: channel.channel_id, label: channel.channel_name }))
    : [];
  return {
    defaultChannelsData,
    currentTeam,
    uiDefaultChannels: uiTeamSettings.defaultChannels,
  };
};

const mapDispatchToProps = {
  fetchDefaultChannels,
  postDefaultChannels,
  fetchAllChannels,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultChannels);
