// Copyright 2019, White Label Communications, LLC, All rights reserved.

import fetch from 'cross-fetch';
import { setUrlParams } from 'shared/URL';

// export function getCompanyDirectory(teamId, searchString, lastKey) {
//     return bindClientFunc(
//         Client4.getCompanyDirectory,
//         CompDirTypes.COMP_DIR_GET_LIST_REQUEST,
//         [CompDirTypes.COMP_DIR_GET_LIST_SUCCESS,CompDirTypes.COMP_DIR_GET_LIST],
//         CompDirTypes.COMP_DIR_GET_LIST_FAILURE,
//         teamId,
//         searchString,
//         lastKey,
//     );
// }

// getCompanyDirectory = async (teamId, searchString, lastKey) => {
//     const params = {};
//     if (searchString && searchString.length > 0) {
//         params.search = searchString;
//     }
//     if (lastKey && lastKey.length > 0) {
//         params.last_key = lastKey;
//     }
//     return this.doFetch(
//         `${this.getTeamRoute(teamId)}/compdir${buildQueryString(params)}`,
//         {method: 'get'},
//     );
// };

export const COMP_DIR_LIST_START = 'COMP_DIR_LIST_START';
function compDirListStart() {
  return {
    type: COMP_DIR_LIST_START,
  };
}

export const COMP_DIR_LIST_SUCCESS = 'COMP_DIR_LIST_SUCCESS';
function compDirListSuccess(response) {
  return {
    type: COMP_DIR_LIST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_COMPLETE_LIST = 'COMP_DIR_COMPLETE_LIST';
function compDirCompleteList(response) {
  return {
    type: COMP_DIR_COMPLETE_LIST,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_LIST_ERROR = 'COMP_DIR_LIST_ERROR';
function compDirListError(error) {
  const ret = {
    type: COMP_DIR_LIST_ERROR,
    error: error.message,
  };
  return ret;
}

export function getCompanyDirectory(teamId, searchString, lastKey) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(compDirListStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    let url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/compdir`;
    if (searchString) {
      url = setUrlParams(url, 'search', searchString);
    }
    if (lastKey) {
      url = setUrlParams(url, 'last_key', lastKey);
    }
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(compDirListError(responseJson));
        } else {
          dispatch(compDirCompleteList(responseJson));
          dispatch(compDirListSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(compDirListError(error));
        } else {
          dispatch(compDirListError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const COMP_DIR_CSV_START = 'COMP_DIR_CSV_START';
function compDirCsvStart() {
  return {
    type: COMP_DIR_CSV_START,
  };
}

export const COMP_DIR_CSV_SUCCESS = 'COMP_DIR_CSV_SUCCESS';
function compDirCsvSuccess(response) {
  return {
    type: COMP_DIR_CSV_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_CSV_ERROR = 'COMP_DIR_CSV_ERROR';
function compDirCsvError(error) {
  const ret = {
    type: COMP_DIR_CSV_ERROR,
    error: error.message,
  };
  return ret;
}

export function getCompDirCsv(teamId, searchString) {
  return async (dispatch) => {
    let allRecords = [];
    let makeAnotherRequest = true;
    dispatch(compDirCsvStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    let url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/compdir`;
    if (searchString) {
      url = setUrlParams(url, 'search', searchString);
    }
    /* eslint-disable */
    //disabling eslint here because i have to use loop
    // since output of first request (i.e. last_key)
    // decides another request to be made or not
    do {
      await fetch(
        url,
        fetchobj,
      )
        .then((response) => (response.json()))
        .then((responseJson) => {
          if (responseJson.status_code && responseJson.status_code !== 200) {
            dispatch(compDirCsvError(responseJson));
            makeAnotherRequest = false;
          } else {
            if(!responseJson.last_key){
              // all records are get now so exit loop
              makeAnotherRequest = false;
            } else {
                url = setUrlParams(url, 'last_key', responseJson.last_key);
            }
            if(responseJson.records && responseJson.records.length > 0) {
              if(allRecords.length === 0) {
                allRecords = responseJson.records;
              } else {
                allRecords = allRecords.concat(responseJson.records);
              }
            } else {
              makeAnotherRequest = false;
            }

          }
        })
        .catch((error) => {
          console.log(error, ': error'); // eslint-disable-line no-console
          if (error.message) {
            dispatch(compDirCsvError(error));
          } else {
            dispatch(compDirCsvError({ message: 'API error' }));
          }
          makeAnotherRequest = false;
        });
    } while (makeAnotherRequest);

    /* eslint-enable */
    dispatch(compDirCsvSuccess(allRecords));
    return { data: allRecords };
  };
}

export const COMP_DIR_POST_START = 'COMP_DIR_POST_START';
function compDirPostStart() {
  return {
    type: COMP_DIR_POST_START,
  };
}

export const COMP_DIR_POST_SUCCESS = 'COMP_DIR_POST_SUCCESS';
function compDirPostSuccess(response) {
  return {
    type: COMP_DIR_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_POST_ERROR = 'COMP_DIR_POST_ERROR';
function compDirPostError(error) {
  const ret = {
    type: COMP_DIR_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postCompanyDirectory(teamId, formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(compDirPostStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/compdir`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(compDirPostError(responseJson));
        } else {
          dispatch(compDirPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(compDirPostError(error));
        } else {
          dispatch(compDirPostError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const COMP_DIR_DELETE_START = 'COMP_DIR_DELETE_START';
function compDirDeleteStart() {
  return {
    type: COMP_DIR_DELETE_START,
  };
}

export const COMP_DIR_DELETE_SUCCESS = 'COMP_DIR_DELETE_SUCCESS';
function compDirDeleteSuccess(response) {
  return {
    type: COMP_DIR_DELETE_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_DELETE_ERROR = 'COMP_DIR_DELETE_ERROR';
function compDirDeleteError(error) {
  const ret = {
    type: COMP_DIR_DELETE_ERROR,
    error: error.message,
  };
  return ret;
}

export function deleteCompanyDirectory(teamId, recordId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(compDirDeleteStart());
    const fetchobj = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/compdir/${recordId}`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(compDirDeleteError(responseJson));
        } else {
          dispatch(compDirDeleteSuccess({ id: recordId }));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(compDirDeleteError(error));
        } else {
          dispatch(compDirDeleteError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const COMP_DIR_PUT_START = 'COMP_DIR_PUT_START';
function compDirPutStart() {
  return {
    type: COMP_DIR_PUT_START,
  };
}

export const COMP_DIR_PUT_SUCCESS = 'COMP_DIR_PUT_SUCCESS';
function compDirPutSuccess(response) {
  return {
    type: COMP_DIR_PUT_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_PUT_ERROR = 'COMP_DIR_PUT_ERROR';
function compDirPutError(error) {
  const ret = {
    type: COMP_DIR_PUT_ERROR,
    error: error.message,
  };
  return ret;
}

export function putCompanyDirectory(teamId, formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(compDirPutStart());
    const fetchobj = {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/compdir`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(compDirPutError(responseJson));
        } else {
          dispatch(compDirPutSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(compDirPutError(error));
        } else {
          dispatch(compDirPutError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const COMP_DIR_POST_MULTI_START = 'COMP_DIR_POST_MULTI_START';
function compDirPostMultiStart() {
  return {
    type: COMP_DIR_POST_MULTI_START,
  };
}

export const COMP_DIR_POST_MULTI_SUCCESS = 'COMP_DIR_POST_MULTI_SUCCESS';
function compDirPostMultiSuccess(response) {
  return {
    type: COMP_DIR_POST_MULTI_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const COMP_DIR_POST_MULTI_ERROR = 'COMP_DIR_POST_MULTI_ERROR';
function compDirPostMultiError(error) {
  const ret = {
    type: COMP_DIR_POST_MULTI_ERROR,
    error: error.message,
  };
  return ret;
}

export function postMultiCompanyDirectory(teamId, formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(compDirPostMultiStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/compdir/multi`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(compDirPostMultiError(responseJson));
        } else {
          dispatch(compDirPostMultiSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(compDirPostMultiError(error));
        } else {
          dispatch(compDirPostMultiError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}
