// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Select from 'react-select';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import Settings from 'images/settings.svg';

const notesRetentionOptions = [
  {
    label: 'Keep deleted notes forever',
    value: 0,
  },
  {
    label: 'Keep deleted notes, but only for a set duration',
    value: 1,
  },
];
const notesRetentionPeriodOptions = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Months',
    value: 'm',
  },
  {
    label: 'Years',
    value: 'y',
  },
];

class NotesRetention extends React.PureComponent {
  constructor(props) {
    super(props);
    const { notesRetentionData } = this.props;
    // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
    let notesCompletedPeriodObj = null;
    let notesCompletedValueObj = null;
    let notesCompletedCount = 0;
    if (notesRetentionData.archived_notes) {
      notesCompletedValueObj = notesRetentionOptions
        .find((option) => option.value === notesRetentionData.archived_notes.value);
      notesCompletedPeriodObj = notesRetentionPeriodOptions
        .find((option) => option.value === notesRetentionData.archived_notes.duration.period);
      notesCompletedCount = notesRetentionData.archived_notes.duration.count;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      notesRetentionData,
      notesRetention: {
        archived_notes: {
          value: notesCompletedValueObj,
          duration: {
            count: notesCompletedCount,
            period: notesCompletedPeriodObj,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (!equal(props.notesRetentionData, state.notesRetentionData)) {
      const { notesRetentionData } = props;
      let notesCompletedPeriodObj = null;
      let notesCompletedValueObj = null;
      let notesCompletedCount = 0;
      if (notesRetentionData.archived_notes) {
        notesCompletedValueObj = notesRetentionOptions
          .find((option) => option.value === notesRetentionData.archived_notes.value);
        notesCompletedPeriodObj = notesRetentionPeriodOptions
          .find((option) => option.value === notesRetentionData.archived_notes.duration.period);
        notesCompletedCount = notesRetentionData.archived_notes.duration.count;
      }
      const newState = {
        notesRetentionData,
        notesRetention: {
          archived_notes: {
            value: notesCompletedValueObj,
            duration: {
              count: notesCompletedCount,
              period: notesCompletedPeriodObj,
            },
          },
        },
      };
      // console.log('newState: ', newState);
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const {
      fetchNotesRetention,
      currentTeam,
    } = this.props;
    fetchNotesRetention(currentTeam.currentTeamId); // get initial notes retention values
  }

  componentDidUpdate(prevProps) {
    const { uiNotesRetention } = this.props;
    if (prevProps.uiNotesRetention.post.status === 1 && uiNotesRetention.post.status === 2) {
      this.setAlerts('', uiNotesRetention.post.error);
    }
    if (prevProps.uiNotesRetention.post.status === 1 && uiNotesRetention.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  changeValue = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const notesName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        notesRetention: {
          ...prevState.notesRetention,
          [notesName]: {
            ...prevState.notesRetention[notesName],
            [name]: selectedValue,
          },
        },
      }),
    );
  }

  changePeriod = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const notesName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        notesRetention: {
          ...prevState.notesRetention,
          [notesName]: {
            ...prevState.notesRetention[notesName],
            duration: {
              ...prevState.notesRetention[notesName].duration,
              [name]: selectedValue,
            },
          },
        },
      }),
    );
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    const splittedNameArr = name.split('-');
    const notesName = splittedNameArr[0];
    const inputName = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        notesRetention: {
          ...prevState.notesRetention,
          [notesName]: {
            ...prevState.notesRetention[notesName],
            duration: {
              ...prevState.notesRetention[notesName].duration,
              [inputName]: value,
            },
          },
        },
      }),
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      postNotesRetention,
      currentTeam,
    } = this.props;
    const { notesRetention } = this.state;
    const notesRetentionSaveObj = { archived_notes: {} };
    notesRetentionSaveObj.archived_notes.value = notesRetention.archived_notes.value.value;
    notesRetentionSaveObj.archived_notes.duration = {
      count: parseInt(notesRetention.archived_notes.duration.count, 10),
      period: notesRetention.archived_notes.duration.period.value,
    };
    // console.log('submit: ', notesRetention); // eslint-disable-line no-console
    postNotesRetention(notesRetentionSaveObj, currentTeam.currentTeamId); // save file retention values
    // const { notesRetention } = this.state;
  }

  render() {
    const {
      notesRetention,
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 2, span: 8 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>NOTES RETENTION</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area retention">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Form onSubmit={this.submitForm}>
                        <Container fluid>
                          <Row>
                            <Col md={7}>
                              <Form.Group controlId="retention-select-notes-archived">
                                <Form.Label>Deleted Notes:</Form.Label>
                                <Select
                                  options={notesRetentionOptions}
                                  value={
                                    (notesRetention
                                      && notesRetention.archived_notes
                                      && notesRetention.archived_notes.value
                                    )
                                      ? notesRetention.archived_notes.value
                                      : null
                                  }
                                  className="select-container"
                                  name="archived_notes-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(notesRetention
                                && notesRetention.archived_notes
                                && notesRetention.archived_notes.value
                                && notesRetention.archived_notes.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (notesRetention
                                          && notesRetention.archived_notes
                                          && notesRetention.archived_notes.duration
                                          && notesRetention.archived_notes.duration.count
                                        )
                                          ? notesRetention.archived_notes.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="archived_notes-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={notesRetentionPeriodOptions}
                                      className="select-container days-type"
                                      value={
                                        (notesRetention
                                          && notesRetention.archived_notes
                                          && notesRetention.archived_notes.duration
                                          && notesRetention.archived_notes.duration.period
                                        )
                                          ? notesRetention.archived_notes.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="archived_notes-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              )
                              : null }
                          </Row>

                          <div className="line-sep" />

                          <Row>
                            <Col md={12}>
                              <Button variant="blue" type="submit">SAVE CHANGES</Button>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

NotesRetention.propTypes = {
  fetchNotesRetention: PropTypes.func.isRequired,
  postNotesRetention: PropTypes.func.isRequired,
  notesRetentionData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiNotesRetention: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

NotesRetention.defaultProps = {
  notesRetentionData: {},
};

export default NotesRetention;
