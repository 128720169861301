// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  COMP_DIR_COMPLETE_LIST,
  COMP_DIR_POST_SUCCESS,
  COMP_DIR_DELETE_SUCCESS,
  COMP_DIR_PUT_SUCCESS,
  COMP_DIR_POST_MULTI_SUCCESS,
} from 'actions/CompanyDirectory';
import {
  ConvertArrayOfObjectsToObjectOfIdWithObject,
  removeFromObjByKey,
} from 'reducers/Helpers';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMP_DIR_COMPLETE_LIST:
      return { ...state, ...ConvertArrayOfObjectsToObjectOfIdWithObject(action.data.records) }; // this value passed in on action
    case COMP_DIR_POST_SUCCESS:
      return { ...state, ...ConvertArrayOfObjectsToObjectOfIdWithObject(action.data) };
    case COMP_DIR_POST_MULTI_SUCCESS:
      return { ...state, ...ConvertArrayOfObjectsToObjectOfIdWithObject(action.data.success) };
    case COMP_DIR_DELETE_SUCCESS:
      return removeFromObjByKey({ ...state }, action.data.id);
    case COMP_DIR_PUT_SUCCESS:
      return {
        ...state,
        [action.data[0].id]: action.data[0],
      };
    case FETCH_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}
