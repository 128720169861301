// uclabs

import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Container,
  Row,
  Col,
  Table,
  Dropdown,
} from 'react-bootstrap';
import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import DotsMenu from 'images/dots.svg';
import AI from 'images/files/ai.svg';
import AVI from 'images/files/avi.svg';
import CSV from 'images/files/csv.svg';
import DOC from 'images/files/doc.svg';
import JPG from 'images/files/jpg.svg';
import MP3 from 'images/files/mp3.svg';
import MP4 from 'images/files/mp4.svg';
import PDF from 'images/files/pdf.svg';
import PNG from 'images/files/png.svg';
import PPT from 'images/files/ppt.svg';
import TXT from 'images/files/txt.svg';
import XLS from 'images/files/xls.svg';
import XML from 'images/files/xml.svg';
import ZIP from 'images/files/zip.svg';
import File from 'images/file.svg';


const ManageFiles = () => (
  <Layout>
    <DashBoard>
      <div className="manage--files">
        <div className="title-section">
          <Container fluid className="no-padding">
            <Row>
              <Col className="text-right" sm={{ offset: 6, span: 3 }}>
                <select>
                  <option> Select Option 1 </option>
                  <option> Select Option 2 </option>
                  <option> Select Option 3 </option>
                </select>
              </Col>
              <Col className="text-right" sm={{ span: 3 }}>
                <input type="search" />
              </Col>
            </Row>
          </Container>
          <h2>
            <img src={File} alt="boomea" />
            {' '}
  Files
          </h2>
        </div>
        <div className="manage-users-table">
          <Container fluid className="no-padding">
            <Row>
              <Col>
                <PerfectScrollbar className="scroll-area">
                  <Table>
                    <thead>
                      <tr>
                        <th>VIDEO FILES</th>
                        <th>IMAGE FILES</th>
                        <th>TEXT FILES</th>
                        <th>XML FILES</th>
                        <th>OFFICE FILES</th>
                        <th>OTHER FILES</th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>4 File(s)</th>
                        <th>3 File(s)</th>
                        <th>2 File(s)</th>
                        <th>4 File(s)</th>
                        <th>4 File(1)</th>
                        <th>5 File(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={AVI} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={MP4} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={AVI} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={AVI} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>

                        <td>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={PNG} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={PNG} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={JPG} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>

                        <td>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={TXT} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={TXT} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                        <td>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={XML} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={XML} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={XML} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={XML} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                        <td>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={PPT} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={CSV} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={XLS} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={DOC} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                        <td>
                          <div className="file-container">
                            <div className="file-type">
                              <img src={AI} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={PDF} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={ZIP} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <div className="file-container">
                            <div className="file-type">
                              <img src={MP3} alt="boomea" />
                            </div>
                            <div className="file-details">
                              <h4>File Name</h4>
                              <p>08-08-2019</p>
                              <small>1.2 MB</small>
                            </div>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                <img src={DotsMenu} alt="boomea" width={16} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Cut
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-4">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-5">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </PerfectScrollbar>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </DashBoard>
  </Layout>
);

export default ManageFiles;
