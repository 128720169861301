// Copyright 2019, White Label Communications, LLC, All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';

class ListRow extends React.Component {
    handleClick = () => {
      const {
        obj,
        objChosen,
      } = this.props;
      objChosen(obj.id);
    }

    render() {
      const {
        obj,
        isSelected,
      } = this.props;
      let truncateText = '';
      let classes = 'more-modal__row';
      if (obj.display_name) {
        truncateText = obj.display_name;
      }
      if (isSelected) {
        classes += ' active';
      }

      return (
        <div
          key={obj.id}
          className={classes}
          onClick={this.handleClick}
          role="presentation"
        >

          <div
            className="more-modal__details"
          >
            <div
              id=""
              className="more-modal__name"
            >
              {truncateText}
            </div>

          </div>

        </div>
      );
    }
}

ListRow.propTypes = {
  obj: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
  objChosen: PropTypes.func.isRequired,
  isSelected: PropTypes.number.isRequired,
};

export default ListRow;
