// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import StepWizard from 'react-step-wizard';
import PropTypes from 'prop-types';

import WhiteLogo from 'images/boomea-white-logo.svg';
import StepOne from './Wizard/StepOne';
import StepTwo from './Wizard/StepTwo';
import StepThree from './Wizard/StepThree';
import StepFour from './Wizard/StepFour';
import StepFive from './Wizard/StepFive';
import StepSix from './Wizard/StepSix';


const maxTeamEmails = 10;// maximum number of team emails admin can enter in step 3

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpValues: {
        companyName: '',
        adminEmail: '',
        teamEmails: [],
      },
    };
  }


  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      signUpValues: {
        ...prevState.signUpValues,
        [name]: value,
      },
    }));
  }

  handleTeamEmailsChange = (valuesArr) => {
    this.setState((prevState) => ({
      ...prevState,
      signUpValues: {
        ...prevState.signUpValues,
        teamEmails: valuesArr,
      },
    }));
  }

  handleSubmit =() => {
    const { signUpValues } = this.state;
    const { fetchSignUpRequest } = this.props;
    fetchSignUpRequest(signUpValues);
  }

  handleStepChange = (currentStep) => {
    if (currentStep.activeStep === 2) {
      document.getElementById('email_address').focus();
    }
  };

  render() {
    const {
      signUpValues,
    } = this.state;
    const { signUpRequest } = this.props;
    return (
      <div className="login" id="login-section">
        <div className="clearfix">
          <div className="col-6 mx-auto">
            <img src={WhiteLogo} className="logo" alt="boomea" />
            <StepWizard onStepChange={this.handleStepChange}>
              <StepOne
                values={signUpValues}
                handleInputChange={this.handleInputChange}
              />
              <StepTwo
                values={signUpValues}
                handleInputChange={this.handleInputChange}
              />
              <StepThree
                handleInputChange={this.handleTeamEmailsChange}
                maxTeamEmails={maxTeamEmails}
              />
              <StepFour
                values={signUpValues}
              />
              <StepFive
                values={signUpValues}
                handleSubmit={this.handleSubmit}
                signUpRequest={signUpRequest}
              />
              <StepSix />
            </StepWizard>
          </div>
        </div>
        <div className="footer">
          <div className="clearfix">
            <div className="col-8 mx-auto">
              <p>&copy; 2019 Boomea. All rights reserved </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  signUpRequest: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  fetchSignUpRequest: PropTypes.func.isRequired,
};

export default SignUp;
