// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchVoiceRetention, postVoiceRetention } from 'actions/VoiceRetentionActions';
import VoiceRetention from './VoiceRetention';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const { currentTeam, uiTeamSettings } = state.Ui;
  return {
    voiceRetentionData: teamSettings.voiceRetention,
    currentTeam,
    uiVoiceRetention: uiTeamSettings.voiceRetention,
  };
};

const mapDispatchToProps = {
  fetchVoiceRetention,
  postVoiceRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRetention);
