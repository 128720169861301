// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchMessageRetention, postMessageRetention } from 'actions/MessageRetentionActions';
import MessageRetention from './MessageRetention';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const { currentTeam, uiTeamSettings } = state.Ui;
  return {
    messageRetentionData: teamSettings.messageRetention,
    currentTeam,
    uiMessageRetention: uiTeamSettings.messageRetention,
  };
};

const mapDispatchToProps = {
  fetchMessageRetention,
  postMessageRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageRetention);
