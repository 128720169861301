// Copyright 2019, White Label Communications, LLC, All rights reserved.
import { combineReducers } from 'redux';
import teams from './Teams';
import compDirCompleteList from './CompDir';
import usersCompleteList from './Users';
import teamSettings from './TeamSettings';
import integrations from './Integrations';

export default combineReducers({
  teams,
  compDirCompleteList,
  usersCompleteList,
  teamSettings,
  integrations,
});
