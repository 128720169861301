// Copyright 2019, White Label Communications, LLC, All rights reserved.
import {
  FETCH_INTEGRATION_DETAIL,
} from 'actions/IntegrationSettingActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INTEGRATION_DETAIL:
      return {
        ...state,
        detail: action.data,
      };
    default:
      return state;
  }
}
