import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ENABLE from 'images/integrations/enable.png';

function ItemData(listItem) {
  const images = require.context('images/integrations/', true);
  const itemCode = listItem.code;
  const ICON = images(`./${itemCode}.png`);
  const { id } = listItem;
  if (listItem.enabled) {
    return (
      <Link to={{
        pathname: '/dashboard/triggers',
        state: {
          integrationId: id,
        },
      }}
      >
        <div className="container">
          <img alt="zendesk" src={ICON} className="imgsize" />
          <img alt="zendesk" src={ENABLE} className="imgsize2" />
          <div className="centered">
            {listItem.name}
          </div>
        </div>
      </Link>
    );
  }
  const link = '/dashboard/settings/';
  return (
    <Link to={link + id}>
      <div className="container">
        <img alt="zendesk" src={ICON} className="imgsize" />
        <div className="centered">
          {listItem.name}
        </div>
      </div>
    </Link>
  );
}

const ListItem = (props) => {
  const { list } = props;
  return (
    <Row className="clearfix">
      { (list && list.length > 0) ? list.map((listItem) => (
        <Col md={3}>
          <div className="fileUploadArea">
            {ItemData(listItem)}
          </div>
        </Col>
      )) : 'No list found '}
    </Row>
  );
};

ListItem.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.string,
      PropTypes.string,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
};

export default ListItem;
