// Copyright 2019, White Label Communications, LLC, All rights reserved.

import fetch from 'cross-fetch';
import { setUrlParams } from 'shared/URL';
import { fetchLogout } from 'actions/LoginActions';

export const USERS_LIST_START = 'USERS_LIST_START';
function usersListStart() {
  return {
    type: USERS_LIST_START,
  };
}

export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
function usersListSuccess(response) {
  return {
    type: USERS_LIST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USERS_COMPLETE_LIST = 'USERS_COMPLETE_LIST';
function usersCompleteList(response) {
  return {
    type: USERS_COMPLETE_LIST,
    data: response,
    timestamp: Date.now(),
  };
}

export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';
function usersListError(error) {
  const ret = {
    type: USERS_LIST_ERROR,
    error: error.message,
  };
  return ret;
}

export function getUsers(teamId, filters, lastKey) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(usersListStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    let url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/users`;
    if (filters && filters.search) {
      url = setUrlParams(url, 'search', filters.search);
    }
    if (filters && filters.primary_owner) {
      url = setUrlParams(url, 'primary_owner', 1);
    }
    if (filters && filters.workspace_admin) {
      url = setUrlParams(url, 'workspace_admin', 1);
    }
    if (filters && filters.member) {
      url = setUrlParams(url, 'member', 1);
    }
    if (filters && filters.deactivated_accounts) {
      url = setUrlParams(url, 'deactivated_accounts', 1);
    }
    if (lastKey) {
      url = setUrlParams(url, 'last_key', lastKey);
    }
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(usersListError(responseJson));
        } else {
          dispatch(usersCompleteList(responseJson));
          const responseWithFilters = responseJson;
          responseWithFilters.filters = filters;
          dispatch(usersListSuccess(responseWithFilters));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(usersListError(error));
        } else {
          dispatch(usersListError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}


export const TEAM_DETAILS = 'TEAM_DETAILS';
function teamDetails(response) {
  return {
    type: TEAM_DETAILS,
    data: response,
    timestamp: Date.now(),
  };
}

export function getTeam(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          // dispatch(usersListError(responseJson));
        } else {
          dispatch(teamDetails(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(usersListError(error));
        } else {
          dispatch(usersListError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const USERS_CSV_START = 'USERS_CSV_START';
function usersCsvStart() {
  return {
    type: USERS_CSV_START,
  };
}

export const USERS_CSV_SUCCESS = 'USERS_CSV_SUCCESS';
function usersCsvSuccess(response) {
  return {
    type: USERS_CSV_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USERS_CSV_ERROR = 'USERS_CSV_ERROR';
function usersCsvError(error) {
  const ret = {
    type: USERS_CSV_ERROR,
    error: error.message,
  };
  return ret;
}

export function getUsersCsv(teamId, filters) {
  return async (dispatch) => {
    let allRecords = [];
    let makeAnotherRequest = true;
    dispatch(usersCsvStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    let url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/users`;
    if (filters && filters.search) {
      url = setUrlParams(url, 'search', filters.search);
    }
    if (filters && filters.primary_owner) {
      url = setUrlParams(url, 'primary_owner', 1);
    }
    if (filters && filters.workspace_admin) {
      url = setUrlParams(url, 'workspace_admin', 1);
    }
    if (filters && filters.member) {
      url = setUrlParams(url, 'member', 1);
    }
    if (filters && filters.deactivated_accounts) {
      url = setUrlParams(url, 'deactivated_accounts', 1);
    }
    /* eslint-disable */
    //disabling eslint here because i have to use loop
    // since output of first request (i.e. last_key)
    // decides another request to be made or not
    do {
      await fetch(
        url,
        fetchobj,
      )
        .then((response) => (response.json()))
        .then((responseJson) => {
          if (responseJson.status_code && responseJson.status_code !== 200) {
            dispatch(usersCsvError(responseJson));
            makeAnotherRequest = false;
          } else {
            if(!responseJson.last_key){
              // all records are get now so exit loop
              makeAnotherRequest = false;
            } else {
                url = setUrlParams(url, 'last_key', responseJson.last_key);
            }
            if(responseJson.records && responseJson.records.length > 0) {
              if(allRecords.length === 0) {
                allRecords = responseJson.records;
              } else {
                allRecords = allRecords.concat(responseJson.records);
              }
            } else {
              makeAnotherRequest = false;
            }

          }
        })
        .catch((error) => {
          console.log(error, ': error'); // eslint-disable-line no-console
          if (error.message) {
            dispatch(usersCsvError(error));
          } else {
            dispatch(usersCsvError({ message: 'API error' }));
          }
          makeAnotherRequest = false;
        });
    } while (makeAnotherRequest);

    /* eslint-enable */
    dispatch(usersCsvSuccess(allRecords));
    return { data: allRecords };
  };
}

export const USER_FORCE_LOGOUT_START = 'USER_FORCE_LOGOUT_START';
function userForceLogoutStart() {
  return {
    type: USER_FORCE_LOGOUT_START,
  };
}

export const USER_FORCE_LOGOUT_SUCCESS = 'USER_FORCE_LOGOUT_SUCCESS';
function userForceLogoutSuccess(response) {
  return {
    type: USER_FORCE_LOGOUT_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USER_FORCE_LOGOUT_ERROR = 'USER_FORCE_LOGOUT_ERROR';
function userForceLogoutError(error) {
  const ret = {
    type: USER_FORCE_LOGOUT_ERROR,
    error: error.message,
  };
  return ret;
}

export function forceLogout(teamId, userId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(userForceLogoutStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/teams/${teamId}/users/${userId}/sessions/revoke/all`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(userForceLogoutError(responseJson));
        } else {
          dispatch(userForceLogoutSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(userForceLogoutError(error));
        } else {
          dispatch(userForceLogoutError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const USER_STATUS_POST_START = 'USER_STATUS_POST_START';
function userStatusPostStart() {
  return {
    type: USER_STATUS_POST_START,
  };
}

export const USER_STATUS_POST_SUCCESS = 'USER_STATUS_POST_SUCCESS';
function userStatusPostSuccess(response) {
  return {
    type: USER_STATUS_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USER_STATUS_POST_ERROR = 'USER_STATUS_POST_ERROR';
function userStatusPostError(error) {
  const ret = {
    type: USER_STATUS_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function usersStatus(userIds) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(userStatusPostStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userIds),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/users/status/ids`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        // if (responseJson.status_code && responseJson.status_code !== 200) {
        // console.log('here1');
        // console.log(responseJson);
        // when api is succesful:
        // [{user_id: "000002useraaaaaaaaaaaaaaaa", status: "online", manual: false, last_activity_at: 1581619315842}]
        // when api fails because no cookies
        // {id: "api.invalid.auth_error", message: "Invalid authentication.", detailed_error: "!ok",
        // request_id: "87v2ty2ben0p9ba7k7me01gtj0", status_code: 401}
        if (responseJson.status_code && responseJson.status_code === 401) {
          // console.log('force logout');
          dispatch(fetchLogout()); // logs out user to clear user cookies
        } else if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(userStatusPostError(responseJson));
        } else {
          dispatch(userStatusPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(userStatusPostError(error));
        } else {
          dispatch(userStatusPostError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const USER_POST_START = 'USER_POST_START';
function userPostStart() {
  return {
    type: USER_POST_START,
  };
}

export const USER_POST_SUCCESS = 'USER_POST_SUCCESS';
function userPostSuccess(response) {
  return {
    type: USER_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USER_POST_ERROR = 'USER_POST_ERROR';
function userPostError(error) {
  const ret = {
    type: USER_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postUser(teamId, formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    // formData.nickname = formData.first_name;
    // formData.username = 'extreme_rotary';
    dispatch(userPostStart());
    const fetchobj = {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/users/${formData.id}/patch`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(userPostError(responseJson));
        } else {
          console.log(responseJson, ': responseJson'); // eslint-disable-line no-console
          dispatch(userPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(userPostError(error));
        } else {
          dispatch(userPostError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const USER_SEND_PASSWORDRESET_START = 'USER_SEND_PASSWORDRESET_START';
function userSendPasswordresetStart() {
  return {
    type: USER_SEND_PASSWORDRESET_START,
  };
}

export const USER_SEND_PASSWORDRESET_SUCCESS = 'USER_SEND_PASSWORDRESET_SUCCESS';
function userSendPasswordresetSuccess(response) {
  return {
    type: USER_SEND_PASSWORDRESET_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USER_SEND_PASSWORDRESET_ERROR = 'USER_SEND_PASSWORDRESET_ERROR';
function userSendPasswordresetError(error) {
  const ret = {
    type: USER_SEND_PASSWORDRESET_ERROR,
    error: error.message,
  };
  return ret;
}

export function sendPasswordreset(formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(userSendPasswordresetStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/users/password/reset/send`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(userSendPasswordresetError(responseJson));
        } else {
          dispatch(userSendPasswordresetSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(userSendPasswordresetError(error));
        } else {
          dispatch(userSendPasswordresetError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const USER_SEND_REINVITATION_START = 'USER_SEND_REINVITATION_START';
function userSendReInvitationStart() {
  return {
    type: USER_SEND_REINVITATION_START,
  };
}

export const USER_SEND_REINVITATION_SUCCESS = 'USER_SEND_REINVITATION_SUCCESS';
function userSendReInvitationSuccess(response) {
  return {
    type: USER_SEND_REINVITATION_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const USER_SEND_REINVITATION_ERROR = 'USER_SEND_REINVITATION_ERROR';
function userSendReInvitationError(error) {
  const ret = {
    type: USER_SEND_REINVITATION_ERROR,
    error: error.message,
  };
  return ret;
}

export function sendReInvitation(formData) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(userSendReInvitationStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/invitee_email`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(userSendReInvitationError(responseJson));
        } else {
          dispatch(userSendReInvitationSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(userSendReInvitationError(error));
        } else {
          dispatch(userSendReInvitationError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}
