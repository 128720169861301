// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import fileRetention from './FileRetention';
import defaultChannels from './DefaultChannels';
import meetingRetention from './MeetingRetention';
import messageRetention from './MessageRetention';
import tasksRetention from './TasksRetention';
import notesRetention from './NotesRetention';
import voiceRetention from './VoiceRetention';


export default combineReducers({
  fileRetention,
  defaultChannels,
  meetingRetention,
  messageRetention,
  tasksRetention,
  notesRetention,
  voiceRetention,
});
