// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  COMP_DIR_LIST_START,
  COMP_DIR_LIST_SUCCESS,
  COMP_DIR_LIST_ERROR,
  COMP_DIR_POST_SUCCESS,
  COMP_DIR_DELETE_SUCCESS,
} from 'actions/CompanyDirectory';
import { ConvertArrayOfObjectsWithIdToArrayOfIds } from 'reducers/Helpers';

const initialState = {
  status: 0, // 0=logged off(init state), 1=loading/checking, 2=error, 3=logged on and auth
  error: '', // holds error mess when status=2
  records: [], // to hold the companyDirectoryData,
  lastKey: '',
  given_search: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMP_DIR_LIST_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
        lastKey: '',
      };
    case COMP_DIR_LIST_SUCCESS:
      if (action.data.new_data) {
        return {
          ...state,
          status: 3, // this status will turn off isLoading
          error: '', // error not needed for status=3, but ok to blank it out
          records: ConvertArrayOfObjectsWithIdToArrayOfIds(action.data.records), // this value passed in on action
          lastKey: action.data.last_key,
          given_search: action.data.given_search,
        };
      }
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
        records: [...state.records, ...ConvertArrayOfObjectsWithIdToArrayOfIds(action.data.records)], // this value passed in on action
        lastKey: action.data.last_key,
        given_search: action.data.given_search,
      };
    case COMP_DIR_LIST_ERROR:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
        lastKey: '',
      };
    case COMP_DIR_POST_SUCCESS:
      return {
        ...state,
        records: [...state.records, action.data[0].id], // array with single record is returned on comp dir add event
      };
    case COMP_DIR_DELETE_SUCCESS:
      return {
        ...state,
        records: (state.records).filter((element) => element !== action.data.id),
      };
    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        // error and email,etc don't matter when status=0
        // but just to be neat reset these values back to init state
        error: '',
        records: [], // empty the records when user logs out
        lastKey: '',
      };
    default:
      return state;
  }
}
