// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import DashBoardHeader from './Header';
import DashBoardSidebar from './Sidebar';

// export default class Layout extends Component {
//   render() {
//     return (
//       <div className="dash--board" id="dashboard">
//         <DashBoardHeader />
//         <DashBoardSidebar />
//         {this.props.children}
//       </div>
//     );
//   }
// }

const Layout = ({ children }) => (
  <div className="dash--board" id="dashboard">
    <DashBoardHeader />
    <DashBoardSidebar />
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
