// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchMeetingRetention, postMeetingRetention } from 'actions/MeetingRetentionActions';
import MeetingRetention from './MeetingRetention';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const {
    currentTeam,
    uiTeamSettings,
  } = state.Ui;
  return {
    meetingRetentionData: teamSettings.meetingRetention,
    currentTeam,
    uiMeetingRetention: uiTeamSettings.meetingRetention,
  };
};

const mapDispatchToProps = {
  fetchMeetingRetention,
  postMeetingRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRetention);
