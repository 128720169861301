// uclabs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Container,
} from 'react-bootstrap';
// import Select from 'react-select';
import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import IntegrationsIcon from '../../images/integrations.svg';
import ListItem from './ListItem';

class ManageIntegrations extends Component {
  componentDidMount = () => {
    const { fetchIntegrationList, currentTeam } = this.props;
    fetchIntegrationList(currentTeam.currentTeamId);
  }

  render() {
    const { list } = this.props;
    return (
      <Layout>
        <DashBoard>
          <div className="manage-teammates">
            <div className="title-section">
              <h2>
                <img src={IntegrationsIcon} alt="boomea" />
              Integrations
              </h2>
            </div>
            <div className="manage-users-table">
              <div className="manage-area">
                <Container fluid>
                  <ListItem list={list.list} />
                </Container>
              </div>
            </div>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}
ManageIntegrations.propTypes = {
  fetchIntegrationList: PropTypes.func.isRequired,
  list: PropTypes.objectOf(
    PropTypes.oneOf([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};
export default ManageIntegrations;
