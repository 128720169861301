// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import BillingIcon from 'images/money.svg';
import changePlan from 'images/change-plan.svg';

const ChangeBoomeaPlan = () => (
  <Layout>
    <DashBoard>
      <div className="billing">
        <div className="title-section">
          <h2>
            <img src={BillingIcon} alt="boomea" />
            BILLING
          </h2>
        </div>
        <Row>
          <Col sm={{ offset: 2, span: 8 }}>
            <div className="manage-users-table change-plan">
              <div className="billing-header">
                <Container fluid>
                  <Row>
                    <Col md={12}>VIEW / CHANGE BOOMEA PLAN</Col>
                  </Row>
                </Container>
              </div>
              <div className="manage-area">
                <Container fluid>
                  <Row>
                    <Col xs={8}>
                      <p>
                        Your workspace is on the Standar plan, paying Monthly.
                      </p>
                      <p>
                        Your plan will renew on
                        {' '}
                        <strong>June 1, 2019</strong>
                        {' '}
  for
                        {' '}
                        <strong>$ 8.88</strong>
  .
                      </p>
                      <ul className="list-styled">
                        <li>$8 for 1 user</li>
                        <li>$0.88 sales tax</li>
                        <li>
                          <Link
                            to="/dashboard/billing/boomea_plans"
                            className="btn btn-blue"
                          >
                            Change Plan
                          </Link>
                        </li>
                      </ul>
                    </Col>
                    <Col xs={4}>
                      <img src={changePlan} alt="boomea" className="img-fluid" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="border-box">
                        <h4>Account Status</h4>
                        <ul>
                          <li>
                            Future charges will be billed to the card ending in
                            1004
                            {' '}
                            <a href="/#">(manage payment method)</a>
  .
                          </li>
                          <li>
                            You are paying for 1 user.
                            {' '}
                            <a href="/#">(manage payment method)</a>
  .
                          </li>
                          <li>
                            Billing emails are sent to abc@whitelabelcomm.com.
                            {' '}
                            <a href="/#">(Adding a billing contact)</a>
  .
                          </li>
                        </ul>
                      </div>
                      <Button variant="blue change-button">OK</Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashBoard>
  </Layout>
);

export default ChangeBoomeaPlan;
