// Copyright 2019, White Label Communications, LLC, All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';

const FormError = (props) => {
  const {
    error,
    errors,
    type,
    margin,
  } = props;
  if (!error && errors.length === 0) {
    return null;
  }

  // look for the first truthy error to display
  let message = error;

  if (!message) {
    errors.forEach((element) => {
      if (element) {
        message = element;
      }
    });
  }

  if (!message) {
    return null;
  }

  if (type === 'modal') {
    return (
      <div className="form-group">
        <label className="col-sm-12 has-error">{message}</label>
      </div>
    );
  }

  if (type === 'info') {
    return (
      <div className="form-group">
        <label className="col-sm-12">{message}</label>
      </div>
    );
  }

  if (type === 'backstage') {
    return (
      <div className="pull-left has-error">
        <label className="control-label">{message}</label>
      </div>
    );
  }

  if (margin) {
    return (
      <div className="form-group has-error">
        <label className="control-label">{message}</label>
      </div>
    );
  }

  if (type === 'list') {
    let list = [];
    list = errors.map((element) => <li key={element.id}>{element}</li>);

    return (
      <div className="form-group has-error">
        <ul className="profile-errors-list">{list}</ul>
      </div>
    );
  }

  return (
    <div className="col-sm-12 has-error">
      <label className="control-label">{message}</label>
    </div>
  );
};

FormError.propTypes = {
  type: PropTypes.node,
  error: PropTypes.node,
  margin: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.node),
};

FormError.defaultProps = {
  error: null,
  errors: [],
  margin: null,
  type: null,
};

export default FormError;
