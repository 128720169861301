// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchSignUpRequest } from 'actions/SignUpActions';
import SignUp from './SignUp';

function mapStateToProps(state) {
  const { signUp } = state.Ui;
  return {
    signUpRequest: signUp.signUp,
  };
}

const mapDispatchToProps = {
  fetchSignUpRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp);
