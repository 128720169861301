// Copyright 2019, White Label Communications, LLC, All rights reserved.
import { connect } from 'react-redux';
import {
  fetchAllChannels,
} from 'actions/DefaultChannelsActions';
import {
  fetchIntegrationDetail,
  putSetting,
  deleteSetting,
} from 'actions/IntegrationSettingActions';
import Triggers from './Triggers';

const mapStateToProps = (state) => {
  const { integrations } = state.Entities;
  const {
    currentTeam,
    integration,
  } = state.Ui;
  const defaultChannelsData = (
    integrations.detail
    && integrations.detail.detail
    && integrations.detail.detail.channel_details
    && integrations.detail.detail.channel_details.length
  ) ? (integrations.detail.detail.channel_details)
      .map((channel) => ({ value: channel.channel_id, label: channel.channel_name }))
    : [];
  const authResponse = { 1: false, 2: true };
  const authentication = (integrations.detail
    && integrations.detail.detail
    && integrations.detail.detail.authentication) ? authResponse[integrations.detail.detail.authentication] : false;
  return {
    currentTeam,
    integrationDetail: integrations.detail,
    integration,
    defaultChannelsData,
    authentication,
  };
};

const mapDispatchToProps = {
  fetchAllChannels,
  fetchIntegrationDetail,
  putSetting,
  deleteSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Triggers);
