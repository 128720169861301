// Copyright 2019, White Label Communications, LLC, All rights reserved.

// Copyright 2019, White Label Communications, LLC, All rights reserved.
import { combineReducers } from 'redux';
import fileRetention from './FileRetention';
import messageRetention from './MessageRetention';
import meetingRetention from './MeetingRetention';
import tasksRetention from './TasksRetention';
import notesRetention from './NotesRetention';
import voiceRetention from './VoiceRetention';
import defaultChannels from './DefaultChannels';

export default combineReducers({
  fileRetention,
  messageRetention,
  meetingRetention,
  tasksRetention,
  notesRetention,
  voiceRetention,
  defaultChannels,
});
