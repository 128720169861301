// Copyright 2019, White Label Communications, LLC, All rights reserved.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

export default class UsageList extends PureComponent {
  render() {
    const { color, icon, listType } = this.props;
    const data2 = {
      labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday ', 'Friday', 'Saturday'],
      datasets: [
        {
          label: '',
          backgroundColor: color,
          borderColor: 'transparent',
          borderWidth: 0,
          hoverBackgroundColor: color,
          hoverBorderColor: 'transparent',
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    };


    return (

      <li className="col-sm-6">
        <div className="media">
          <div className="usage-type-icon mr-3" style={{ backgroundColor: color }}>
            <img src={icon} alt="boomea" />
          </div>

          <div className="media-body">
            <h4 className="mt-0">1,12,215</h4>
            <div className="list-type">{listType}</div>
          </div>

          <div className="usage-type-ico2">
            <Bar
              data={data2}
              width={80}
              height={50}
              display={false}
              options={{
                maintainAspectRatio: true,
                legend: false,
                tooltip: false,
                scales: {
                  xAxes: [{ ticks: { display: false }, gridLines: { display: false } }],
                  yAxes: [{ ticks: { display: false }, gridLines: { display: false } }],
                },
                tooltips: {
                  enabled: false,
                },
              }}
            />
          </div>
        </div>
      </li>
    );
  }
}


UsageList.propTypes = {
  color: PropTypes.isRequired,
  icon: PropTypes.isRequired,
  listType: PropTypes.isRequired,
};
