// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Select from 'react-select';
import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import userIcon from 'images/dashboard/dashboard.svg';
import ChatIcon from 'images/dashboard/chat.svg';
import HistoryIcon from 'images/dashboard/history.svg';
import CallRecordingsIcon from 'images/dashboard/mic.svg';
import VoiceMailsIcon from 'images/dashboard/voice-mail.svg';
import TasksIcon from 'images/dashboard/task.svg';
import NotesIcon from 'images/dashboard/note.svg';
import FilesIcon from 'images/dashboard/attacgments.svg';
import MeetingsIcon from 'images/dashboard/meetings.svg';
import UsageList from './usage/usageList';
import UsageData from './usage/usageData';

const data = {
  labels: [
    'Files',
    'Meeting Recordings',
    'Tasks',
    'Notes',
    'Voice Mails',
  ],
  datasets: [{
    data: [3000, 2500, 2000, 1800, 3000],
    backgroundColor: [
      '#f67600',
      '#D11B88',
      '#1BD19F',
      '#1BBED1',
      '#1B7ED1',
    ],
  }],
};


const databydate = [
  { value: 'Today', label: 'Today' },
  { value: 'This Week', label: 'This Week' },
  { value: 'This Month', label: 'This Month' },
];


export default function PageHome() {
  // need to convert classes to clcasses specific to home page
  const ListChartWidth = 100;
  return (
    <Layout>
      <DashBoard>
        <div className="dashboard-metrics" id="dashboard_metrics">
          <div className="title-section">
            <h2>
              <img src={userIcon} alt="boomea" />
              {' '}
              Dashboard
            </h2>
          </div>
          <div className="metrics-area">
            <div className="metrics-area">
              <div className="metrics-area-controller">
                <div className="row">
                  <div className="col-md-6" id="left_metrics">
                    <div className="card">
                      <div className="card-title-section">
                        <div className="title">Your usage</div>
                        <Select
                          options={databydate}
                          className="select-container"
                        />
                      </div>
                      <ul className="row usage-list">
                        <UsageList width={ListChartWidth} icon={ChatIcon} color="#f67600" listType="chat messages" />
                        <UsageList width={ListChartWidth} icon={HistoryIcon} color="#D1D11B" listType="call history records" />
                        <UsageList width={ListChartWidth} icon={CallRecordingsIcon} color="#1BBED1" listType="call recordings" />
                        <UsageList width={ListChartWidth} icon={VoiceMailsIcon} color="#D11B88" listType="voicemails" />
                        <UsageList width={ListChartWidth} icon={TasksIcon} color="#FFA200" listType="tasks" />
                        <UsageList width={ListChartWidth} icon={NotesIcon} color="#1BD19F" listType="notes" />
                        <UsageList width={ListChartWidth} icon={FilesIcon} color="#9BD11B" listType="attachments (files)" />
                        <UsageList width={ListChartWidth} icon={MeetingsIcon} color="#1B7ED1" listType="meeting records" />
                      </ul>
                    </div>
                    <div className="card">
                      <div className="card-title-section">
                        <div className="title">Your usage in GB</div>
                        <Select
                          options={databydate}
                          className="select-container"
                        />
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <Doughnut
                            data={data}
                            display={false}
                            width={300}
                            height={300}
                            options={{ maintainAspectRatio: false, legend: false }}
                          />
                        </div>
                        <div className="col-sm-6">
                          <table className="table legend">
                            <tbody>
                              <tr>
                                <td width="20"><div className="legend-badge" style={{ backgroundColor: '#f67600' }} /></td>
                                <td>Files</td>
                                <td>3.0 GB</td>
                              </tr>

                              <tr>
                                <td><div className="legend-badge" style={{ backgroundColor: '#D11B88' }} /></td>
                                <td>Meeting Records</td>
                                <td>3.0 GB</td>
                              </tr>
                              <tr>
                                <td><div className="legend-badge" style={{ backgroundColor: '#1BD19F' }} /></td>
                                <td>Tasks</td>
                                <td>3.0 GB</td>
                              </tr>
                              <tr>
                                <td><div className="legend-badge" style={{ backgroundColor: '#1BBED1' }} /></td>
                                <td>Notes</td>
                                <td>3.0 GB</td>
                              </tr>
                              <tr>
                                <td><div className="legend-badge" style={{ backgroundColor: '#1B7ED1' }} /></td>
                                <td>Voice Mails</td>
                                <td>3.0 GB</td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="totalgb">
                            <h4>Total</h4>
                            <h2>
Data usage
                              <span>12.3 GB</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6" id="right_metrics">
                    <UsageData />
                    <div className="total-entries">Team Members: 120</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashBoard>
    </Layout>
  );
}
