// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  FETCH_MEETING_RETENTION_START,
  FETCH_MEETING_RETENTION_SUCCESS,
  FETCH_MEETING_RETENTION_ERROR,
} from 'actions/MeetingRetentionActions';

const initialState = {
  status: 0, // 0=logged off(init state), 1=loading/checking, 2=error, 3=logged on and auth
  error: '', // holds error mess when status=2
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MEETING_RETENTION_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
      };
    case FETCH_MEETING_RETENTION_SUCCESS:
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
      };
    case FETCH_MEETING_RETENTION_ERROR:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
      };
    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        // error and email,etc don't matter when status=0
        // but just to be neat reset these values back to init state
        error: '',
      };
    default:
      return state;
  }
}
