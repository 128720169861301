// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import ChangeBoomeaPlan from './ChangePlan';

function mapDispatchToProps() {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(ChangeBoomeaPlan);
