// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchTasksRetention, postTasksRetention } from 'actions/TasksRetentionActions';
import TasksRetention from './TasksRetention';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const { currentTeam, uiTeamSettings } = state.Ui;
  return {
    tasksRetentionData: teamSettings.tasksRetention,
    currentTeam,
    uiTasksRetention: uiTeamSettings.tasksRetention,
  };
};

const mapDispatchToProps = {
  fetchTasksRetention,
  postTasksRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksRetention);
