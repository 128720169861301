// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Select from 'react-select';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import Settings from 'images/settings.svg';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';

const messageRetentionOptions = [
  {
    label: 'Keep all messages forever',
    value: 0,
  },
  {
    label: 'Keep all messages, but only for a set duration',
    value: 1,
  },
];
const messageRetentionDeleteOptions = [
  {
    label: "Users can't delete messages",
    value: 0,
  },
  {
    label: 'Users can mark messages deleted and they will be removed after',
    value: 1,
  },
];

const messageRetentiontDuration = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Months',
    value: 'm',
  },
  {
    label: 'Years',
    value: 'y',
  },
];

class MessageRetention extends React.PureComponent {
  constructor(props) {
    super(props);
    const { messageRetentionData } = this.props;
    // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
    let publicChannelPeriodObj = null;
    let publicChannelValueObj = null;
    let privateChannelPeriodObj = null;
    let privateChannelValueObj = null;
    let directChannelPeriodObj = null;
    let directChannelValueObj = null;
    let userActionPeriodObj = { label: 'Days', value: 'd' };
    let userActionValueObj = { label: "Users can't delete messages", value: 0 };
    let publicChannelCount = 0;
    let privateChannelCount = 0;
    let directChannelCount = 0;
    let userActionCount = 0;
    if (messageRetentionData.public_channels) {
      publicChannelValueObj = messageRetentionOptions
        .find((option) => option.value === messageRetentionData.public_channels.value);
      publicChannelPeriodObj = messageRetentiontDuration
        .find((option) => option.value === messageRetentionData.public_channels.duration.period);
      publicChannelCount = messageRetentionData.public_channels.duration.count;
    }
    if (messageRetentionData.private_channels) {
      privateChannelValueObj = messageRetentionOptions
        .find((option) => option.value === messageRetentionData.private_channels.value);
      privateChannelPeriodObj = messageRetentiontDuration
        .find((option) => option.value === messageRetentionData.private_channels.duration.period);
      privateChannelCount = messageRetentionData.private_channels.duration.count;
    }
    if (messageRetentionData.direct_messages) {
      directChannelValueObj = messageRetentionOptions
        .find((option) => option.value === messageRetentionData.direct_messages.value);
      directChannelPeriodObj = messageRetentiontDuration
        .find((option) => option.value === messageRetentionData.direct_messages.duration.period);
      directChannelCount = messageRetentionData.direct_messages.duration.count;
    }
    if (messageRetentionData.user_actions) {
      userActionValueObj = messageRetentionDeleteOptions
        .find((option) => option.value === messageRetentionData.user_actions.value);
      userActionPeriodObj = messageRetentiontDuration
        .find((option) => option.value === messageRetentionData.user_actions.duration.period);
      userActionCount = messageRetentionData.user_actions.duration.count;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      messageRetentionData,
      messageRetention: {
        public_channels: {
          value: publicChannelValueObj,
          duration: {
            count: publicChannelCount,
            period: publicChannelPeriodObj,
          },
        },
        private_channels: {
          value: privateChannelValueObj,
          duration: {
            count: privateChannelCount,
            period: privateChannelPeriodObj,
          },
        },
        direct_messages: {
          value: directChannelValueObj,
          duration: {
            count: directChannelCount,
            period: directChannelPeriodObj,
          },
        },
        user_actions: {
          value: userActionValueObj,
          duration: {
            count: userActionCount,
            period: userActionPeriodObj,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (!equal(props.messageRetentionData, state.messageRetentionData)) {
      const { messageRetentionData } = props;
      // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
      let publicChannelPeriodObj = null;
      let publicChannelValueObj = null;
      let privateChannelPeriodObj = null;
      let privateChannelValueObj = null;
      let directChannelPeriodObj = null;
      let directChannelValueObj = null;
      let userActionPeriodObj = null;
      let userActionValueObj = null;
      let publicChannelCount = 0;
      let privateChannelCount = 0;
      let directChannelCount = 0;
      let userActionCount = 0;
      if (messageRetentionData.public_channels) {
        publicChannelValueObj = messageRetentionOptions
          .find((option) => option.value === messageRetentionData.public_channels.value);
        publicChannelPeriodObj = messageRetentiontDuration
          .find((option) => option.value === messageRetentionData.public_channels.duration.period);
        publicChannelCount = messageRetentionData.public_channels.duration.count;
      }
      if (messageRetentionData.private_channels) {
        privateChannelValueObj = messageRetentionOptions
          .find((option) => option.value === messageRetentionData.private_channels.value);
        privateChannelPeriodObj = messageRetentiontDuration
          .find((option) => option.value === messageRetentionData.private_channels.duration.period);
        privateChannelCount = messageRetentionData.private_channels.duration.count;
      }
      if (messageRetentionData.direct_messages) {
        directChannelValueObj = messageRetentionOptions
          .find((option) => option.value === messageRetentionData.direct_messages.value);
        directChannelPeriodObj = messageRetentiontDuration
          .find((option) => option.value === messageRetentionData.direct_messages.duration.period);
        directChannelCount = messageRetentionData.direct_messages.duration.count;
      }
      if (messageRetentionData.user_actions) {
        userActionValueObj = messageRetentionDeleteOptions
          .find((option) => option.value === messageRetentionData.user_actions.value);
        userActionPeriodObj = messageRetentiontDuration
          .find((option) => option.value === messageRetentionData.user_actions.duration.period);
        userActionCount = messageRetentionData.user_actions.duration.count;
      }

      const newState = {
        messageRetentionData,
        messageRetention: {
          public_channels: {
            value: publicChannelValueObj,
            duration: {
              count: publicChannelCount,
              period: publicChannelPeriodObj,
            },
          },
          private_channels: {
            value: privateChannelValueObj,
            duration: {
              count: privateChannelCount,
              period: privateChannelPeriodObj,
            },
          },
          direct_messages: {
            value: directChannelValueObj,
            duration: {
              count: directChannelCount,
              period: directChannelPeriodObj,
            },
          },
          user_actions: {
            value: userActionValueObj,
            duration: {
              count: userActionCount,
              period: userActionPeriodObj,
            },
          },
        },
      };
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const {
      fetchMessageRetention,
      currentTeam,
    } = this.props;
    fetchMessageRetention(currentTeam.currentTeamId); // get initial message retention values
  }

  componentDidUpdate(prevProps) {
    const { uiMessageRetention } = this.props;
    if (prevProps.uiMessageRetention.post.status === 1 && uiMessageRetention.post.status === 2) {
      this.setAlerts('', uiMessageRetention.post.error);
    }
    if (prevProps.uiMessageRetention.post.status === 1 && uiMessageRetention.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }


  changeValue = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const channelName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        messageRetention: {
          ...prevState.messageRetention,
          [channelName]: {
            ...prevState.messageRetention[channelName],
            [name]: selectedValue,
          },
        },
      }),
    );
  }

  changeValueMessage = (selectedValue) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        messageRetention: {
          ...prevState.messageRetention,
          direct_messages: {
            ...prevState.messageRetention.direct_messages,
            canmark: selectedValue,
          },
        },
      }),
    );
  }

  changePeriod = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const channelName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        messageRetention: {
          ...prevState.messageRetention,
          [channelName]: {
            ...prevState.messageRetention[channelName],
            duration: {
              ...prevState.messageRetention[channelName].duration,
              [name]: selectedValue,
            },
          },
        },
      }),
    );
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    const splittedNameArr = name.split('-');
    const channelName = splittedNameArr[0];
    const inputName = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        messageRetention: {
          ...prevState.messageRetention,
          [channelName]: {
            ...prevState.messageRetention[channelName],
            duration: {
              ...prevState.messageRetention[channelName].duration,
              [inputName]: value,
            },
          },
        },
      }),
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      postMessageRetention,
      currentTeam,
    } = this.props;
    const { messageRetention } = this.state;
    const messageRetentionSaveObj = {
      public_channels: {}, private_channels: {}, direct_messages: {}, user_actions: {},
    };
    messageRetentionSaveObj.public_channels.value = messageRetention.public_channels.value.value;
    messageRetentionSaveObj.public_channels.duration = {
      count: parseInt(messageRetention.public_channels.duration.count, 10),
      period: messageRetention.public_channels.duration.period.value,
    };
    messageRetentionSaveObj.private_channels.value = messageRetention.private_channels.value.value;
    messageRetentionSaveObj.private_channels.duration = {
      count: parseInt(messageRetention.private_channels.duration.count, 10),
      period: messageRetention.private_channels.duration.period.value,
    };
    messageRetentionSaveObj.direct_messages.value = messageRetention.direct_messages.value.value;
    messageRetentionSaveObj.direct_messages.duration = {
      count: parseInt(messageRetention.direct_messages.duration.count, 10),
      period: messageRetention.direct_messages.duration.period.value,
    };
    messageRetentionSaveObj.user_actions.value = messageRetention.user_actions.value.value;
    messageRetentionSaveObj.user_actions.duration = {
      count: parseInt(messageRetention.user_actions.duration.count, 10),
      period: messageRetention.user_actions.duration.period.value,
    };
    // console.log('submit: ', messageRetention); // eslint-disable-line no-console
    postMessageRetention(messageRetentionSaveObj, currentTeam.currentTeamId); // save file retention values
    // const { messageRetention } = this.state;
  }

  render() {
    const {
      messageRetention,
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 0, span: 12 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>MESSAGE RETENTION</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area retention">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Form onSubmit={this.submitForm}>
                        <Container fluid>
                          <Row>
                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>PUBLIC CHANNELS:</Form.Label>
                                <Select
                                  options={messageRetentionOptions}
                                  value={
                                    (messageRetention
                                      && messageRetention.public_channels
                                      && messageRetention.public_channels.value
                                    )
                                      ? messageRetention.public_channels.value
                                      : null
                                  }
                                  className="select-container"
                                  name="public_channels-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(messageRetention
                              && messageRetention.public_channels
                              && messageRetention.public_channels.value
                              && messageRetention.public_channels.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE MESSAGES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (messageRetention
                                          && messageRetention.public_channels
                                          && messageRetention.public_channels.duration
                                          && messageRetention.public_channels.duration.count
                                        )
                                          ? messageRetention.public_channels.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="public_channels-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={messageRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (messageRetention
                                          && messageRetention.public_channels
                                          && messageRetention.public_channels.duration
                                          && messageRetention.public_channels.duration.period
                                        )
                                          ? messageRetention.public_channels.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="public_channels-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              )
                              : null }

                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>PRIVATE CHANNELS:</Form.Label>
                                <Select
                                  options={messageRetentionOptions}
                                  value={
                                    (messageRetention
                                      && messageRetention.private_channels
                                      && messageRetention.private_channels.value
                                    )
                                      ? messageRetention.private_channels.value
                                      : null
                                  }
                                  className="select-container"
                                  name="private_channels-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>
                            {(messageRetention
                              && messageRetention.private_channels
                              && messageRetention.private_channels.value
                              && messageRetention.private_channels.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE MESSAGES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (messageRetention
                                          && messageRetention.private_channels
                                          && messageRetention.private_channels.duration
                                          && messageRetention.private_channels.duration.count
                                        )
                                          ? messageRetention.private_channels.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="private_channels-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={messageRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (messageRetention
                                          && messageRetention.private_channels
                                          && messageRetention.private_channels.duration
                                          && messageRetention.private_channels.duration.period
                                        )
                                          ? messageRetention.private_channels.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="private_channels-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }

                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>DIRECT MESSAGES:</Form.Label>
                                <Select
                                  options={messageRetentionOptions}
                                  className="select-container"
                                  name="direct_messages-value"
                                  value={
                                    (messageRetention
                                      && messageRetention.direct_messages
                                      && messageRetention.direct_messages.value
                                    )
                                      ? messageRetention.direct_messages.value
                                      : null
                                  }
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>


                            {(messageRetention
                              && messageRetention.direct_messages
                              && ((messageRetention.direct_messages.value
                              && messageRetention.direct_messages.value.value))
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE MESSAGES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (messageRetention
                                          && messageRetention.direct_messages
                                          && messageRetention.direct_messages.duration
                                          && messageRetention.direct_messages.duration.count
                                        )
                                          ? messageRetention.direct_messages.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="direct_messages-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={messageRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (messageRetention
                                          && messageRetention.direct_messages
                                          && messageRetention.direct_messages.duration
                                          && messageRetention.direct_messages.duration.period
                                        )
                                          ? messageRetention.direct_messages.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="direct_messages-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }
                            <Col md={7}>
                              <Form.Group controlId="retention-select-message">
                                <Form.Label>USER ACTIONS:</Form.Label>
                                <Select
                                  options={messageRetentionDeleteOptions}
                                  className="select-container"
                                  name="user_actions-value"
                                  value={
                                    (messageRetention
                                      && messageRetention.user_actions
                                      && messageRetention.user_actions.value
                                    )
                                      ? messageRetention.user_actions.value
                                      : null
                                  }
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(messageRetention
                              && messageRetention.user_actions
                              && ((messageRetention.user_actions.value
                              && messageRetention.user_actions.value.value))
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE MESSAGES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (messageRetention
                                          && messageRetention.user_actions
                                          && messageRetention.user_actions.duration
                                          && messageRetention.user_actions.duration.count
                                        )
                                          ? messageRetention.user_actions.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="user_actions-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={messageRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (messageRetention
                                          && messageRetention.user_actions
                                          && messageRetention.user_actions.duration
                                          && messageRetention.user_actions.duration.period
                                        )
                                          ? messageRetention.user_actions.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="user_actions-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }

                          </Row>

                          <div className="line-sep" />

                          <Row>
                            {/* <Col md={12} >
                                  <div className='retention-message'>
                                  <Form.Group>
                                      <div className="custom-control custom-checkbox mb-1">
                                          <input type="checkbox" className="custom-control-input"
                                          id="workspaceoverride" required />
                                          <label className="custom-control-label" htmlFor="workspaceoverride">
                                            Let workspace members override these settings:
                                          </label>

                                      </div>

                                      <div id="workspaceoverride-message">
                                          This setting allows members to set their own retention period -
                                          making it shorter or longer - for private channels and direct messages.
                                          Owners will be able to adjust this for public channels, too. Learn more
                                      </div>
                                  </Form.Group>

                                  </div>
                              </Col> */}
                            <Col md={12}>
                              <Button type="submit" variant="blue">SAVE CHANGES</Button>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

MessageRetention.propTypes = {
  fetchMessageRetention: PropTypes.func.isRequired,
  postMessageRetention: PropTypes.func.isRequired,
  messageRetentionData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiMessageRetention: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

MessageRetention.defaultProps = {
  messageRetentionData: {},
};

export default MessageRetention;
