// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

class Logout extends React.Component {
  componentDidMount() {
    // need to clear the cookie, which is used in PrivateRouting and other functions
    // document.cookie = 'MMUSERID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    // call api to cleanup on the server end
    // api triggers actions to fix store for logout
    const { fetchLogout } = this.props;
    fetchLogout();
    // redirect to /, handled in render()
  }

  render() {
    return <Redirect to="/" />;
  }
}

Logout.propTypes = {
  fetchLogout: PropTypes.func.isRequired,
};

export default Logout;
