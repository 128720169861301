export const INTEGRATION_DELETE_START = 'INTEGRATION_DELETE_START';
function integrationDeleteStart() {
  return {
    type: INTEGRATION_DELETE_START,
  };
}
export const INTEGRATION_DELETE_ERROR = 'INTEGRATION_DELETE_ERROR';
function integrationDeleteError(error) {
  const ret = {
    type: INTEGRATION_DELETE_ERROR,
    error: error.message,
  };
  return ret;
}
export const INTEGRATION_DELETE_SUCCESS = 'INTEGRATION_DELETE_SUCCESS';
function integrationDeleteSuccess(response) {
  return {
    type: INTEGRATION_DELETE_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}
export function deleteSetting(teamId, formData) {
  const integrationId = formData.integration;
  return async (dispatch) => {
    const data = formData;
    const jsonResponse = true;
    dispatch(integrationDeleteStart());
    const fetchobj = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/integrations/${integrationId}`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(integrationDeleteError(responseJson));
        } else {
          dispatch(integrationDeleteSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(integrationDeleteError(error));
        } else {
          dispatch(integrationDeleteError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}
export const INTEGRATION_PUT_START = 'INTEGRATION_PUT_START';
function integrationPutStart() {
  return {
    type: INTEGRATION_PUT_START,
  };
}
export const INTEGRATION_PUT_ERROR = 'INTEGRATION_PUT_ERROR';
function integrationPutError(error) {
  const ret = {
    type: INTEGRATION_PUT_ERROR,
    error: error.message,
  };
  return ret;
}
export const INTEGRATION_PUT_SUCCESS = 'INTEGRATION_PUT_SUCCESS';
function integrationPutSuccess(response) {
  return {
    type: INTEGRATION_PUT_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}
export function putSetting(teamId, formData) {
  const integrationId = formData.integration;
  return async (dispatch) => {
    const data = formData;
    const jsonResponse = true;
    dispatch(integrationPutStart());
    const fetchobj = {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/integrations/${integrationId}`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(integrationPutError(responseJson));
        } else {
          dispatch(integrationPutSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(integrationPutError(error));
        } else {
          dispatch(integrationPutError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const INTEGRATION_POST_START = 'INTEGRATION_POST_START';
function integrationPostStart() {
  return {
    type: INTEGRATION_POST_START,
  };
}
export const INTEGRATION_POST_ERROR = 'INTEGRATION_POST_ERROR';
function integrationPostError(error) {
  const ret = {
    type: INTEGRATION_POST_ERROR,
    error: error.message,
  };
  return ret;
}
export const INTEGRATION_POST_SUCCESS = 'INTEGRATION_POST_SUCCESS';
function integrationPostSuccess(response) {
  return {
    type: INTEGRATION_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}
export function postSetting(teamId, integrationId, formData) {
  return async (dispatch) => {
    const data = formData;
    data.authentication = formData.authentication ? 2 : 1;
    const jsonResponse = true;
    dispatch(integrationPostStart());
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/integrations/${integrationId}`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(integrationPostError(responseJson));
        } else {
          dispatch(integrationPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(integrationPostError(error));
        } else {
          dispatch(integrationPostError({ message: 'API error' }));
        }
      });
    return { data: jsonResponse };
  };
}

export const FETCH_INTEGRATION_LIST = 'FETCH_INTEGRATION_LIST';
export function fetchIntegrationList(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/integrations`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        dispatch({
          type: FETCH_INTEGRATION_LIST,
          data: responseJson,
        });
      })
      .catch(() => {

      });

    return { data: jsonResponse };
  };
}

export const FETCH_INTEGRATION_DETAIL = 'FETCH_INTEGRATION_DETAIL';
export function fetchIntegrationDetail(integrationId, teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/integrations/${integrationId}`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        dispatch({
          type: FETCH_INTEGRATION_DETAIL,
          data: responseJson,
        });
      })
      .catch(() => {

      });

    return { data: jsonResponse };
  };
}
