// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchLogin, setTeamModal } from 'actions/LoginActions';
import PageLogin from './PageLogin';

const mapStateToProps = (state) => (
  {
    login: state.Ui.login,
  }
);

const mapDispatchToProps = {
  fetchLogin,
  setTeamModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLogin);
