// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AccessDenied from 'components/PageAccessDenied';
import CookieLogin from 'components/CookieLogin';

class PrivateRoute extends React.Component {
  componentDidUpdate(prevProps) {
    const { stateUiLoginStatus, history } = this.props;
    if (prevProps.stateUiLoginStatus === 1 && stateUiLoginStatus === 3) {
      history.push({
        pathname: '/users/users',
        state: { showSwitchTeamModal: true },
      });
    }
  }

  getCookie = (cname) => {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  render() {
    const {
      component,
      stateUiLoginStatus,
      ...rest
    } = this.props;
    const Component = component;
    // let permSetting = false;
    const permSetting = true; // for now no permission tests
    // if (stateTenantsTenants && stateTenantsTenants[stateTenantsCurrent]) {
    //  permSetting = ((stateTenantsTenants[stateTenantsCurrent].permission) & (2 ** permission)) > 0;
    // }
    return (
      <Route
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
        render={(props) => {
          if (stateUiLoginStatus === 3) {
            return (
              permSetting
                ? <Component {...props} /> // eslint-disable-line react/jsx-props-no-spreading
                : <AccessDenied />
            );
          }
          // console.log('PrivateRoute, before cookie test');
          // console.log(document.cookie);
          // console.log(document.cookie.indexOf('MMUSERID='));
          // console.log(document.cookie.indexOf('MMAUTHTOKEN='));

          // PrivateRoute, before cookie test
          // MMUSERID=000002useraaaaaaaaaaaaaaaa
          // 0
          // PrivateRoute, cookie MMUSERID

          // if (document.cookie.indexOf('MMUSERID=') > -1) {

          // OPEN need to change this to MMUSER since for security reasons,
          // MMAUTHTOKEN is not available to js, per settings in ucserver
          if (document.cookie.indexOf('MMUSERID=') > -1) {
            // not logged in according to store, but do have a session
            // console.log('PrivateRoute, cookie MMAUTHTOKEN');
            const token = this.getCookie('MMAUTHTOKEN');
            return (
              <CookieLogin
                cookie={token}
                {...props} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          }
          // console.log('PrivateRoute, before redirect');
          return (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location },
            }}
            />
          );
        }}
        // render={(props) => (
        //   stateUiLoginStatus === 3
        //     ? permSetting
        //       ? <Component {...props} />
        //       : <AccessDenied />
        //     : (
        //       <Redirect to={{
        //         pathname: '/login',
        //         state: { from: props.location },
        //       }}
        //       />
        //     )
        // )}
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  stateUiLoginStatus: PropTypes.number.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

function mapStateToProps(state) {
  return {
    stateUiLoginStatus: state.Ui.login.status,
    // stateTenantsCurrent: state.tenants.current,
    // stateTenantsTenants: state.tenants.tenants,
  };
}
// adding withRouter so that props.history can be accessed
export default connect(mapStateToProps)(withRouter(PrivateRoute));
