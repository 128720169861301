// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchNotesRetention, postNotesRetention } from 'actions/NotesRetentionActions';
import NotesRetention from './NotesRetention';

const mapStateToProps = (state) => {
  const { teamSettings } = state.Entities;
  const { currentTeam, uiTeamSettings } = state.Ui;
  return {
    notesRetentionData: teamSettings.notesRetention,
    currentTeam,
    uiNotesRetention: uiTeamSettings.notesRetention,
  };
};

const mapDispatchToProps = {
  fetchNotesRetention,
  postNotesRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesRetention);
