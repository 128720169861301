// Copyright 2019, White Label Communications, LLC, All rights reserved.
import { connect } from 'react-redux';
import { postSetting } from 'actions/IntegrationSettingActions';
import ManageSettings from './Settings';

const mapStateToProps = (state) => {
  const { integrations } = state.Entities;
  const {
    currentTeam,
    integration,
  } = state.Ui;
  return {
    integrations: integrations.setting,
    currentTeam,
    integration,
  };
};

const mapDispatchToProps = {
  postSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSettings);
