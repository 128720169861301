// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import FormError from 'components/Common/FormError';
import WhiteLogo from 'images/boomea-white-logo.svg';

class SignUpFinal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: null,
      redirectToLogin: false,
      signUpFinalValues: {
        first: '',
        last: '',
        password: '',
        password2: '',
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    const { signUpFinal, login } = this.props;

    if (prevProps.login.status === 1 && login.status === 3) {
      window.location = '/';
    }

    if (prevProps.signUpFinal.status === 1 && signUpFinal.status === 3) {
      this.updateServerError('redirectToLogin', true); // if signup Request started and got success then show user final screen/success message
    }
    if (prevProps.signUpFinal.status === 1 && signUpFinal.status === 2) {
      this.updateServerError('serverError', signUpFinal.error);
    }
  };

  updateServerError = (name, message) => {
    this.setState({ [name]: message }); // this is added just to avoid eslint error of cannot use setState inside componentDidUpdate method
  };

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      signUpFinalValues: {
        ...prevState.signUpFinalValues,
        [name]: value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { fetchSignUpFinal, token } = this.props;
    const { signUpFinalValues } = this.state;
    if (signUpFinalValues.password !== signUpFinalValues.password2) {
      this.setState({ serverError: 'Passwords must be matching. ' });
      return;
    }
    fetchSignUpFinal({
      ...signUpFinalValues,
      token,
    });
  };

  render() {
    const { signUpFinalValues, serverError, redirectToLogin } = this.state;
    const { signUpFinal } = this.props;
    // const { signUpFinal } = this.props; console.log(signUpFinal, 'signUpFinal'); // eslint-disable-line no-console
    if (redirectToLogin) {
      return (
        <Redirect
          to="/login"
          loginMessage="SignUp Completed Successfully. You can now login into your boomea Admin account"
        />
      );
    }
    return (
      <>
        <div className="login" id="login-section">
          <div className="clearfix">
            <div className="col-6 mx-auto">
              <img src={WhiteLogo} className="logo" alt="boomea" />
              <div className="form-area step-five">
                <h1>We need few more details to complete the signup process</h1>
                {signUpFinal.status === 1 ? (
                  'Loading...'
                ) : (
                  <FormError error={serverError} />
                )}
                <form onSubmit={this.handleSubmit}>
                  <input
                    required
                    type="text"
                    className="team-name email-address"
                    placeholder="Enter Your First Name"
                    name="first"
                    value={signUpFinalValues.first}
                    onChange={this.handleInputChange}
                  />
                  <input
                    required
                    type="text"
                    className="team-name email-address"
                    placeholder="Enter Your Last Name"
                    name="last"
                    value={signUpFinalValues.last}
                    onChange={this.handleInputChange}
                  />
                  <input
                    required
                    type="password"
                    className="team-name email-address"
                    placeholder="Enter Password"
                    name="password"
                    value={signUpFinalValues.password}
                    onChange={this.handleInputChange}
                  />
                  <input
                    required
                    type="password"
                    className="team-name email-address"
                    placeholder="Re-Enter Password"
                    name="password2"
                    value={signUpFinalValues.password2}
                    onChange={this.handleInputChange}
                  />
                  <button type="submit" className="entry-button">
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="clearfix">
              <div className="col-8 mx-auto">
                <p>&copy; 2019 Boomea. All rights reserved </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SignUpFinal.propTypes = {
  fetchSignUpFinal: PropTypes.func.isRequired,
  login: PropTypes.oneOfType([PropTypes.object]).isRequired,
  signUpFinal: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  token: PropTypes.string.isRequired,
};

SignUpFinal.defaultProps = {
  signUpFinal: {},
};

export default SignUpFinal;
