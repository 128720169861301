// Copyright 2019, White Label Communications, LLC, All rights reserved.

// in the future this file may have additional exports, so overriding default until the 2nd function is added
// eslint-disable-next-line import/prefer-default-export
export function setUrlParams(url, key, value) {
  const newUrl = url.split('?');
  const newParam = new URLSearchParams(newUrl[1]);
  newParam.set(key, value);
  newUrl[1] = newParam.toString();
  return newUrl.join('?');
}
