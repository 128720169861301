// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import BillingIcon from 'images/money.svg';
import Download from 'images/download.svg';

import Picker from 'components/Common/DateRange/Picker';
import HistoryList from './components/HistoryList';

export default class BillingHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: moment(),
      endValue: moment().add(5, 'days'),
      startOpen: false,
      endOpen: false,
    };
  }

  onStartOpenChange = (startOpen) => {
    this.setState({
      startOpen,
    });
  };

  onEndOpenChange = (endOpen) => {
    this.setState({
      endOpen,
    });
  };

  onStartChange = (value) => {
    this.setState({
      startValue: value[0],
      startOpen: false,
      endOpen: true,
    });
  };

  onEndChange = (value) => {
    this.setState({
      endValue: value[1],
    });
  };

  disabledStartDate = (endValue) => {
    if (!endValue) {
      return false;
    }
    const { startValue } = this.state;
    if (!startValue) {
      return false;
    }
    return endValue.diff(startValue, 'days') < 1;
  };

  render() {
    const {
      startValue,
      startOpen,
      endValue,
      endOpen,
    } = this.state;

    return (
      <Layout>
        <DashBoard>
          <div className="billing">
            <div className="title-section">
              <h2>
                <img src={BillingIcon} alt="boomea" />
                BILLING
              </h2>
            </div>
            <div className="manage-users-table">
              <div className="billing-header">
                <Container fluid>
                  <Row>
                    <Col md={12}>History</Col>
                  </Row>
                </Container>
              </div>
              <div className="manage-area billing-history">
                <Container fluid>
                  <Row>
                    <Col md={5}>
                      <table className="daterange-table">
                        <tbody>
                          <tr className="no-border">
                            <td style={{ width: '55px' }}>Form:</td>
                            <td style={{ width: '150px' }}>
                              <Picker
                                onOpenChange={this.onStartOpenChange}
                                type="start"
                                showValue={startValue}
                                open={startOpen}
                                value={[startValue, endValue]}
                                onChange={this.onStartChange}
                                Placeholder="Start Date"
                                className="date-picker"
                              />
                            </td>
                            <td style={{ width: '35px' }}>To:</td>
                            <td style={{ width: '150px' }}>
                              <Picker
                                onOpenChange={this.onEndOpenChange}
                                open={endOpen}
                                type="end"
                                showValue={endValue}
                                disabledDate={this.disabledStartDate}
                                value={[
                                  startValue,
                                  endValue,
                                ]}
                                onChange={this.onEndChange}
                                className="date-picker"
                              />
                            </td>
                            <td>
                              <Button variant="blue">GO</Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col md={{ offset: 3, span: 4 }}>
                      <Button variant="blue download-invoice">
                        <img src={Download} alt="" />
                        Download Summery
                        {' '}
                      </Button>
                    </Col>
                    <Col md={12}>
                      <PerfectScrollbar className="scroll-area">
                        <div className="table-area billing-history-table">
                          <Table>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Credit Balance</th>
                                <th>Invoice</th>
                              </tr>
                            </thead>
                            <tbody>
                              <HistoryList />
                              <HistoryList />
                              <HistoryList />
                            </tbody>
                          </Table>
                        </div>
                      </PerfectScrollbar>
                      <p>Note : this report updated every hour</p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}
