// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import {
  getCompanyDirectory,
  postCompanyDirectory,
  deleteCompanyDirectory,
  putCompanyDirectory,
  postMultiCompanyDirectory,
  getCompDirCsv,
} from 'actions/CompanyDirectory';
import CompanyDirectory from './CompanyDirectory';

const mapStateToProps = (state) => {
  const { login, currentTeam, compDirCurrent } = state.Ui;
  const { teams, compDirCompleteList } = state.Entities;
  return {
    loginData: login,
    teamsData: teams,
    currentTeam,
    compDirCurrentList: compDirCurrent.list,
    compDirPut: compDirCurrent.put,
    compDirDelete: compDirCurrent.deleteCompDir,
    compDirPost: compDirCurrent.post,
    compDirPostMulti: compDirCurrent.postMulti,
    compDirCompleteList,
  };
};

const mapDispatchToProps = {
  getCompanyDirectory,
  postCompanyDirectory,
  deleteCompanyDirectory,
  putCompanyDirectory,
  postMultiCompanyDirectory,
  getCompDirCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDirectory);
