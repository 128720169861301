// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  SET_CURRENT_TEAM_ID,
} from 'actions/TeamActions';

import { FETCH_LOGOUT_SUCCESS } from 'actions/LoginActions';

const initialState = {
  currentTeamId: '', // initially set currentTeamId as blanck
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TEAM_ID:
      return {
        ...state,
        currentTeamId: action.data, // set current Team Id
      };
    case FETCH_LOGOUT_SUCCESS:
      // even though start does the same thing, log user out with status=0
      return {
        ...state,
        currentTeamId: '', // reset current team Id on logout
      };
    default:
      return state;
  }
}
