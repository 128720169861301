// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import AsyncPaginate from 'react-select-async-paginate';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import Settings from 'images/settings.svg';


const customStyles = {
  multiValue: (styles) => (
    {
      ...styles,
      backgroundColor: '#599ad1',
      borderRadius: '15px',
      height: '30px',
    }
  ),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
    fontSize: '100%',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'white',
    height: '30px',
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
      cursor: 'pointer',
    },
  }),
};

class DefaultChannels extends React.Component {
  constructor(props) {
    super(props);
    const { defaultChannelsData } = this.props;
    // console.log('constructor', defaultChannelsData); // eslint-disable-line no-console
    let defaultChannels = null;
    if (defaultChannelsData) {
      defaultChannels = defaultChannelsData;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      defaultChannelsData,
      defaultChannels,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (!equal(props.defaultChannelsData, state.defaultChannelsData)) {
      const { defaultChannelsData } = props;
      // console.log('constructor', defaultChannelsData); // eslint-disable-line no-console
      let defaultChannels = null;
      if (defaultChannelsData) {
        defaultChannels = defaultChannelsData;
      }
      const newState = {
        defaultChannelsData,
        defaultChannels,
      };
      // console.log('newState: ', newState);
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const { fetchDefaultChannels, currentTeam } = this.props;
    fetchDefaultChannels(currentTeam.currentTeamId); // get initial file retention values
  }

  componentDidUpdate(prevProps) {
    const { uiDefaultChannels } = this.props;
    if (prevProps.uiDefaultChannels.post.status === 1 && uiDefaultChannels.post.status === 2) {
      this.setAlerts('', uiDefaultChannels.post.error);
    }
    if (prevProps.uiDefaultChannels.post.status === 1 && uiDefaultChannels.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  selectChannel = (option) => {
    if (option && option.length > 0) {
      const checkIfGenChnlExist = option.filter((channel) => (channel.label).toLowerCase() === 'general');
      if (checkIfGenChnlExist.length > 0) {
        this.setState({
          defaultChannels: option,
        });
      }
    } else {
      const { defaultChannels } = this.state;
      let checkIfGenChnlExist = [];
      if (defaultChannels.find((channel) => (channel.label).toLowerCase() === 'general')) {
        checkIfGenChnlExist = defaultChannels.filter((channel) => (channel.label).toLowerCase() === 'general');
      }
      this.setState({
        defaultChannels: checkIfGenChnlExist,
      });
    }
  }

  loadOptions = async (search, loadedOptions, { page, currentTeamId }) => {
    const { fetchAllChannels } = this.props;
    let channelSuggestions = [];
    const res = await fetchAllChannels(currentTeamId, page, search);
    if (!res || !res.data || !res.data.length) { // if not result or not result data or not result data length THEN Return
      /* channelSuggestions = [
        {
          value: '1',
          label: 'General',
        },
        {
          value: '2',
          label: 'General2',
        },
        {
          value: '3',
          label: 'General 3 General 3',
        },
        {
          value: '4',
          label: 'General 4 General 4',
        },
      ]; */
      return {
        options: channelSuggestions,
        hasMore: false,
        additional: {
          page,
        },
      };
    }
    // Transform the channels to what react-select expects
    channelSuggestions = res.data.map((channel) => ({ value: channel.id, label: channel.display_name }));
    let hasMore = false;
    if (channelSuggestions.length >= 100) {
      hasMore = true;
    }
    return {
      options: channelSuggestions,
      hasMore,
      additional: {
        page: hasMore ? page + 1 : page,
      },
    };
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const { postDefaultChannels, currentTeam } = this.props;
    const { defaultChannels } = { ...this.state };
    const defaultChannelsSaveObj = {};
    defaultChannelsSaveObj.default_channels = defaultChannels.map((channel) => channel.value);
    // console.log('defaultChannelsSaveObj: ', defaultChannelsSaveObj);
    if (defaultChannelsSaveObj.default_channels.length === 0) {
      this.setAlerts('', 'Please select a channel');
      return false;
    }
    postDefaultChannels(defaultChannelsSaveObj, currentTeam.currentTeamId); // save file retention values
    // const { defaultChannels } = this.state;
    return true;
  }

  render() {
    const {
      defaultChannels,
      successAlert,
      errorAlert,
    } = this.state;
    const { currentTeam } = this.props;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings" id="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 2, span: 8 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>DEFAULT CHANNELS</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Container fluid>
                        <Form onSubmit={this.submitForm}>
                          <Row>
                            <Col md={12}>
                              <p>
                                Choose the channels new members will automatically
                                be added to (in addition to #general)
                              </p>
                              <AsyncPaginate
                                className="select-container"
                                name="defaultChannels"
                                onChange={this.selectChannel}
                                value={defaultChannels}
                                loadOptions={this.loadOptions}
                                isMulti
                                isSearchable
                                styles={customStyles}
                                closeMenuOnSelect
                                additional={{
                                  page: 0,
                                  currentTeamId: currentTeam.currentTeamId,
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <Button type="submit" variant="blue save-change">
                                SAVE CHANGES
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

DefaultChannels.propTypes = {
  fetchAllChannels: PropTypes.func.isRequired,
  fetchDefaultChannels: PropTypes.func.isRequired,
  postDefaultChannels: PropTypes.func.isRequired,
  defaultChannelsData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiDefaultChannels: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

DefaultChannels.defaultProps = {
  defaultChannelsData: {},
};

export default DefaultChannels;
