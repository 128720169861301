// Copyright 2019, White Label Communications, LLC, All rights reserved.

// import fetch from 'cross-fetch';

export const FETCH_TASKS_RETENTION_START = 'FETCH_TASKS_RETENTION_START';
function fetchTasksRetentionStart() {
  return {
    type: FETCH_TASKS_RETENTION_START,
  };
}

export const FETCH_TASKS_RETENTION_SUCCESS = 'FETCH_TASKS_RETENTION_SUCCESS';
function fetchTasksRetentionSuccess(json) {
  return {
    type: FETCH_TASKS_RETENTION_SUCCESS,
    data: json,
  };
}

export const FETCH_TASKS_RETENTION_ERROR = 'FETCH_TASKS_RETENTION_ERROR';
function fetchTasksRetentionError(json) {
  return {
    type: FETCH_TASKS_RETENTION_ERROR,
    error: json.message,
  };
}

export function fetchTasksRetention(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fetchTasksRetentionStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/task_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchTasksRetentionError(responseJson));
        } else {
          dispatch(fetchTasksRetentionSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchTasksRetentionError(error));
        } else {
          dispatch(fetchTasksRetentionError({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const TASKS_RETENTION_POST_START = 'TASKS_RETENTION_POST_START';
function tasksRetentionPostStart() {
  return {
    type: TASKS_RETENTION_POST_START,
  };
}

export const TASKS_RETENTION_POST_SUCCESS = 'TASKS_RETENTION_POST_SUCCESS';
function tasksRetentionPostSuccess(response) {
  return {
    type: TASKS_RETENTION_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const TASKS_RETENTION_POST_ERROR = 'TASKS_RETENTION_POST_ERROR';
function tasksRetentionPostError(error) {
  const ret = {
    type: TASKS_RETENTION_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postTasksRetention(formData, teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(tasksRetentionPostStart());
    // console.log('tasksRetention2: ', formData); // eslint-disable-line no-console
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/task_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(tasksRetentionPostError(responseJson));
        } else {
          dispatch(tasksRetentionPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(tasksRetentionPostError(error));
        } else {
          dispatch(tasksRetentionPostError({ message: 'API error' }));
        }
      });
    dispatch(tasksRetentionPostSuccess(formData));
    return { data: jsonResponse };
  };
}
