// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

class CookieLogin extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // console.log('CookieLogin, constructor');
  //   // console.log(this.props);
  // }
  componentDidMount() {
    // console.log('CookieLogin, componentDidMount');
    const { fetchCookieLogin } = this.props;
    // console.log(fetchCookieLogin);
    fetchCookieLogin();
  }


  render() {
    // if (login.status === 3) {
    //   return <Redirect to={from} />;
    // }
    const { login } = this.props;
    const permissionError = { pathname: '/permission_error' };
    if (login.status === 2 && login.error === 'permission_error') {
      // specific case of permission, redirect user to 403 permission page
      return <Redirect to={permissionError} />;
    }
    return (
      <>
        Loading...
      </>
    );
  }
}

CookieLogin.propTypes = {
  fetchCookieLogin: PropTypes.func.isRequired,
  login: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CookieLogin;
