// Copyright 2019, White Label Communications, LLC, All rights reserved.

import fetch from 'cross-fetch';

export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
function fetchTeamsStart() {
  return {
    type: FETCH_TEAMS_START,
  };
}

export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
function fetchTeamsSuccess(teamMembersResponse, teamsData) {
  return {
    type: FETCH_TEAMS_SUCCESS,
    data: { teamMembersResponse, teamsData },
  };
}

export const FETCH_TEAMS_FAIL = 'FETCH_TEAMS_FAIL';
function fetchTeamsFail(json) {
  return {
    type: FETCH_TEAMS_FAIL,
    error: json.message,
  };
}

export const SET_CURRENT_TEAM_ID = 'SET_CURRENT_TEAM_ID';
export function setCurrentTeamId(id) {
  return {
    type: SET_CURRENT_TEAM_ID,
    data: id,
  };
}

export function fetchTeamMembers(userId, teamsData) {
  return async (dispatch) => {
    let teamMembersResponse = false;
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/users/${userId}/teams/members`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchTeamsFail(responseJson));
        } else {
          teamMembersResponse = responseJson;
          dispatch(fetchTeamsSuccess(teamMembersResponse, teamsData));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchTeamsFail(error));
        } else {
          dispatch(fetchTeamsFail({ message: 'API error' }));
        }
      });
    return { teamMembersResponse };
  };
}

export function fetchTeams(userId) {
  return async (dispatch) => {
    let teamsData = false;
    dispatch(fetchTeamsStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/users/${userId}/teams`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchTeamsFail(responseJson));
        } else {
          teamsData = responseJson;
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchTeamsFail(error));
        } else {
          dispatch(fetchTeamsFail({ message: 'API error' }));
        }
      });
    let teamMembersResponse = false;
    if (teamsData) {
      teamMembersResponse = await dispatch(fetchTeamMembers(userId, teamsData));
    }
    return { data: teamMembersResponse };
  };
}
