// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Select from 'react-select';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import Settings from 'images/settings.svg';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';

const meetingHistoryRetentionOptions = [
  {
    label: 'Keep meeting history forever',
    value: 0,
  },
  {
    label: 'Keep meeting history, but only for a set duration',
    value: 1,
  },
];
const meetingRecordingsRetentionOptions = [
  {
    label: 'Keep meeting recordings forever',
    value: 0,
  },
  {
    label: 'Keep meeting recordings, but only for a set duration',
    value: 1,
  },
];
const meetingRetentionPeriodOptions = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Months',
    value: 'm',
  },
  {
    label: 'Years',
    value: 'y',
  },
];

class MeetingRetention extends React.PureComponent {
  constructor(props) {
    super(props);
    const { meetingRetentionData } = this.props;
    // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
    let meetingHistoryPeriodObj = null;
    let meetingHistoryValueObj = null;
    let meetingRecordingsPeriodObj = null;
    let meetingRecordingsValueObj = null;
    let meetingHistoryCount = 0;
    let meetingRecordingsCount = 0;
    if (meetingRetentionData.meeting_history) {
      meetingHistoryValueObj = meetingHistoryRetentionOptions
        .find((option) => option.value === meetingRetentionData.meeting_history.value);
      meetingHistoryPeriodObj = meetingRetentionPeriodOptions
        .find((option) => option.value === meetingRetentionData.meeting_history.duration.period);
      meetingHistoryCount = meetingRetentionData.meeting_history.duration.count;
    }
    if (meetingRetentionData.meeting_recordings) {
      meetingRecordingsValueObj = meetingRecordingsRetentionOptions
        .find((option) => option.value === meetingRetentionData.meeting_recordings.value);
      meetingRecordingsPeriodObj = meetingRetentionPeriodOptions
        .find((option) => option.value === meetingRetentionData.meeting_recordings.duration.period);
      meetingRecordingsCount = meetingRetentionData.meeting_recordings.duration.count;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      meetingRetentionData,
      meetingRetention: {
        meeting_history: {
          value: meetingHistoryValueObj,
          duration: {
            count: meetingHistoryCount,
            period: meetingHistoryPeriodObj,
          },
        },
        meeting_recordings: {
          value: meetingRecordingsValueObj,
          duration: {
            count: meetingRecordingsCount,
            period: meetingRecordingsPeriodObj,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (!equal(props.meetingRetentionData, state.meetingRetentionData)) {
      const { meetingRetentionData } = props;
      let meetingHistoryPeriodObj = null;
      let meetingHistoryValueObj = null;
      let meetingRecordingsPeriodObj = null;
      let meetingRecordingsValueObj = null;
      let meetingHistoryCount = 0;
      let meetingRecordingsCount = 0;
      if (meetingRetentionData.meeting_history) {
        meetingHistoryValueObj = meetingHistoryRetentionOptions
          .find((option) => option.value === meetingRetentionData.meeting_history.value);
        meetingHistoryPeriodObj = meetingRetentionPeriodOptions
          .find((option) => option.value === meetingRetentionData.meeting_history.duration.period);
        meetingHistoryCount = meetingRetentionData.meeting_history.duration.count;
      }
      if (meetingRetentionData.meeting_recordings) {
        meetingRecordingsValueObj = meetingRecordingsRetentionOptions
          .find((option) => option.value === meetingRetentionData.meeting_recordings.value);
        meetingRecordingsPeriodObj = meetingRetentionPeriodOptions
          .find((option) => option.value === meetingRetentionData.meeting_recordings.duration.period);
        meetingRecordingsCount = meetingRetentionData.meeting_recordings.duration.count;
      }
      const newState = {
        meetingRetentionData,
        meetingRetention: {
          meeting_history: {
            value: meetingHistoryValueObj,
            duration: {
              count: meetingHistoryCount,
              period: meetingHistoryPeriodObj,
            },
          },
          meeting_recordings: {
            value: meetingRecordingsValueObj,
            duration: {
              count: meetingRecordingsCount,
              period: meetingRecordingsPeriodObj,
            },
          },
        },
      };
      // console.log('newState: ', newState);
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const {
      fetchMeetingRetention,
      currentTeam,
    } = this.props;
    fetchMeetingRetention(currentTeam.currentTeamId); // get initial meeting retention values
  }

  componentDidUpdate(prevProps) {
    const { uiMeetingRetention } = this.props;
    if (prevProps.uiMeetingRetention.post.status === 1 && uiMeetingRetention.post.status === 2) {
      this.setAlerts('', uiMeetingRetention.post.error);
    }
    if (prevProps.uiMeetingRetention.post.status === 1 && uiMeetingRetention.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }


  changeValue = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const meetingName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        meetingRetention: {
          ...prevState.meetingRetention,
          [meetingName]: {
            ...prevState.meetingRetention[meetingName],
            [name]: selectedValue,
          },
        },
      }),
    );
  }

  changePeriod = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const meetingName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        meetingRetention: {
          ...prevState.meetingRetention,
          [meetingName]: {
            ...prevState.meetingRetention[meetingName],
            duration: {
              ...prevState.meetingRetention[meetingName].duration,
              [name]: selectedValue,
            },
          },
        },
      }),
    );
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    const splittedNameArr = name.split('-');
    const meetingName = splittedNameArr[0];
    const inputName = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        meetingRetention: {
          ...prevState.meetingRetention,
          [meetingName]: {
            ...prevState.meetingRetention[meetingName],
            duration: {
              ...prevState.meetingRetention[meetingName].duration,
              [inputName]: value,
            },
          },
        },
      }),
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      postMeetingRetention,
      currentTeam,
    } = this.props;
    const { meetingRetention } = this.state;
    const meetingRetentionSaveObj = { meeting_history: {}, meeting_recordings: {} };
    meetingRetentionSaveObj.meeting_history.value = meetingRetention.meeting_history.value.value;
    meetingRetentionSaveObj.meeting_history.duration = {
      count: parseInt(meetingRetention.meeting_history.duration.count, 10),
      period: meetingRetention.meeting_history.duration.period.value,
    };
    meetingRetentionSaveObj.meeting_recordings.value = meetingRetention.meeting_recordings.value.value;
    meetingRetentionSaveObj.meeting_recordings.duration = {
      count: parseInt(meetingRetention.meeting_recordings.duration.count, 10),
      period: meetingRetention.meeting_recordings.duration.period.value,
    };
    // console.log('submit: ', meetingRetention); // eslint-disable-line no-console
    postMeetingRetention(meetingRetentionSaveObj, currentTeam.currentTeamId); // save file retention values
    // const { meetingRetention } = this.state;
  }

  render() {
    const {
      meetingRetention,
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 2, span: 8 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>MEETING RETENTION</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area retention">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Form onSubmit={this.submitForm}>
                        <Container fluid>
                          <Row>
                            <Col md={7}>
                              <Form.Group controlId="retention-select-meeting-history">
                                <Form.Label>Meeting History:</Form.Label>
                                <Select
                                  options={meetingHistoryRetentionOptions}
                                  value={
                                    (meetingRetention
                                      && meetingRetention.meeting_history
                                      && meetingRetention.meeting_history.value
                                    )
                                      ? meetingRetention.meeting_history.value
                                      : null
                                  }
                                  className="select-container"
                                  name="meeting_history-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(meetingRetention
                                && meetingRetention.meeting_history
                                && meetingRetention.meeting_history.value
                                && meetingRetention.meeting_history.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (meetingRetention
                                          && meetingRetention.meeting_history
                                          && meetingRetention.meeting_history.duration
                                          && meetingRetention.meeting_history.duration.count
                                        )
                                          ? meetingRetention.meeting_history.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="meeting_history-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={meetingRetentionPeriodOptions}
                                      className="select-container days-type"
                                      value={
                                        (meetingRetention
                                          && meetingRetention.meeting_history
                                          && meetingRetention.meeting_history.duration
                                          && meetingRetention.meeting_history.duration.period
                                        )
                                          ? meetingRetention.meeting_history.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="meeting_history-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              )
                              : null }

                            <Col md={7}>
                              <Form.Group controlId="retention-select-meeting-recordings">
                                <Form.Label>Meeting Recordings:</Form.Label>
                                <Select
                                  options={meetingRecordingsRetentionOptions}
                                  value={
                                    (meetingRetention
                                      && meetingRetention.meeting_recordings
                                      && meetingRetention.meeting_recordings.value
                                    )
                                      ? meetingRetention.meeting_recordings.value
                                      : null
                                  }
                                  className="select-container"
                                  name="meeting_recordings-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(meetingRetention
                                && meetingRetention.meeting_recordings
                                && meetingRetention.meeting_recordings.value
                                && meetingRetention.meeting_recordings.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (meetingRetention
                                          && meetingRetention.meeting_recordings
                                          && meetingRetention.meeting_recordings.duration
                                          && meetingRetention.meeting_recordings.duration.count
                                        )
                                          ? meetingRetention.meeting_recordings.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="meeting_recordings-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={meetingRetentionPeriodOptions}
                                      className="select-container days-type"
                                      value={
                                        (meetingRetention
                                          && meetingRetention.meeting_recordings
                                          && meetingRetention.meeting_recordings.duration
                                          && meetingRetention.meeting_recordings.duration.period
                                        )
                                          ? meetingRetention.meeting_recordings.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="meeting_recordings-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              )
                              : null }
                          </Row>

                          <div className="line-sep" />

                          <Row>
                            <Col md={12}>
                              <Button variant="blue" type="submit">SAVE CHANGES</Button>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

MeetingRetention.propTypes = {
  fetchMeetingRetention: PropTypes.func.isRequired,
  postMeetingRetention: PropTypes.func.isRequired,
  meetingRetentionData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiMeetingRetention: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

MeetingRetention.defaultProps = {
  meetingRetentionData: {},
};

export default MeetingRetention;
