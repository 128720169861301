// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchSignUpFinal } from 'actions/SignUpActions';
import SignUpFinal from './SignUpFinal';

function mapStateToProps(state) {
  const { signUp, login } = state.Ui;
  return {
    signUpFinal: signUp.signUpFinal,
    login,
  };
}

const mapDispatchToProps = {
  fetchSignUpFinal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpFinal);
