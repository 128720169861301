// Copyright 2019, White Label Communications, LLC, All rights reserved.

// import fetch from 'cross-fetch';

export const FETCH_MEETING_RETENTION_START = 'FETCH_MEETING_RETENTION_START';
function fetchMeetingRetentionStart() {
  return {
    type: FETCH_MEETING_RETENTION_START,
  };
}

export const FETCH_MEETING_RETENTION_SUCCESS = 'FETCH_MEETING_RETENTION_SUCCESS';
function fetchMeetingRetentionSuccess(json) {
  return {
    type: FETCH_MEETING_RETENTION_SUCCESS,
    data: json,
  };
}

export const FETCH_MEETING_RETENTION_ERROR = 'FETCH_MEETING_RETENTION_ERROR';
function fetchMeetingRetentionError(json) {
  return {
    type: FETCH_MEETING_RETENTION_ERROR,
    error: json.message,
  };
}

export function fetchMeetingRetention(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fetchMeetingRetentionStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/meeting_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchMeetingRetentionError(responseJson));
        } else {
          dispatch(fetchMeetingRetentionSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchMeetingRetentionError(error));
        } else {
          dispatch(fetchMeetingRetentionError({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const MEETING_RETENTION_POST_START = 'MEETING_RETENTION_POST_START';
function meetingRetentionPostStart() {
  return {
    type: MEETING_RETENTION_POST_START,
  };
}

export const MEETING_RETENTION_POST_SUCCESS = 'MEETING_RETENTION_POST_SUCCESS';
function meetingRetentionPostSuccess(response) {
  return {
    type: MEETING_RETENTION_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const MEETING_RETENTION_POST_ERROR = 'MEETING_RETENTION_POST_ERROR';
function meetingRetentionPostError(error) {
  const ret = {
    type: MEETING_RETENTION_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postMeetingRetention(formData, teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(meetingRetentionPostStart());
    // console.log('meetingRetention2: ', formData); // eslint-disable-line no-console
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/meeting_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(meetingRetentionPostError(responseJson));
        } else {
          dispatch(meetingRetentionPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(meetingRetentionPostError(error));
        } else {
          dispatch(meetingRetentionPostError({ message: 'API error' }));
        }
      });
    dispatch(meetingRetentionPostSuccess(formData));
    return { data: jsonResponse };
  };
}
