// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchIntegrationList } from 'actions/IntegrationSettingActions';
import ManageIntegrations from './Integrations';

const mapStateToProps = (state) => {
  const { integrations } = state.Entities;
  const {
    currentTeam,
  } = state.Ui;
  return { list: integrations.list, currentTeam };
};

const mapDispatchToProps = {
  fetchIntegrationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageIntegrations);
