// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_TEAMS_START,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAIL,
} from 'actions/TeamActions';

import {
  ConvertArrayOfObjectsToObjectOfTeamIdWithObject,
  ConvertArrayOfObjectsToObjectOfIdWithObjectAndCurrentState,
} from 'reducers/Helpers';
import { FETCH_LOGOUT_SUCCESS } from 'actions/LoginActions';

const initialState = {
  status: 0, // 0=init state, 1=loading/checking, 2=error, 3=logged on and auth
  error: '', // holds error mess when status=2
  data: {}, // holds logged in user team data when status = 3
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TEAMS_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
        data: {}, // empty the logged in user data
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
        data: ConvertArrayOfObjectsToObjectOfIdWithObjectAndCurrentState(
          ConvertArrayOfObjectsToObjectOfTeamIdWithObject(action.data.teamMembersResponse),
          action.data.teamsData,
        ), // combine data from teamsData and teamMembersData to form a final teams Data object
      };
    case FETCH_TEAMS_FAIL:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
        data: {}, // empty the logged in user data
      };
    case FETCH_LOGOUT_SUCCESS:
      // even though start does the same thing, log user out with status=0
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        error: '',
        data: {}, // empty the data if login fail
      };
    default:
      return state;
  }
}
