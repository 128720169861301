// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
} from 'actions/LoginActions';
import {
  SIGNUP_FINAL_START,
  SIGNUP_FINAL_SUCCESS,
  SIGNUP_FINAL_FAILURE,
} from 'actions/SignUpActions';

const initialState = {
  status: 0, // 0=logged off(init state), 1=loading/checking, 2=error, 3=success
  error: '', // holds error mess when status=2
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNUP_FINAL_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        // error and email,etc don't matter when status =1
        // but just to be neat reset these values back to init state
        error: '',
      };
    case SIGNUP_FINAL_SUCCESS:
      return {
        ...state,
        status: 3, // this status will turn off isLoading
        error: '', // error not needed for status=3, but ok to blank it out
      };
    case SIGNUP_FINAL_FAILURE:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
      };
    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        status: 0, // 0 means logged out, also starting init state
        error: '',
      };
    default:
      // console.log(state, ': signupfinalred'); // eslint-disable-line no-console
      return state;
  }
}
