// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import login from './Login';
import currentTeam from './Teams';
import compDirCurrent from './CompDir';
import signUp from './SignUp';
import usersCurrent from './Users';
import uiTeamSettings from './TeamSettings';
import integration from './Integration';

export default combineReducers({
  login,
  currentTeam,
  compDirCurrent,
  signUp,
  usersCurrent,
  uiTeamSettings,
  integration,
});
