// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';

import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import BillingIcon from 'images/money.svg';
import csv from 'images/cc.svg';

const PaymentMethod = () => (
  <Layout>
    <DashBoard>
      <div className="billing">
        <div className="title-section">
          <h2>
            <img src={BillingIcon} alt="boomea" />
            BILLING
          </h2>
        </div>
        <div className="manage-users-table billing-method">
          <div className="billing-header">
            <Container fluid>
              <Row>
                <Col md={12}>Payment Method</Col>
              </Row>
            </Container>
          </div>
          <div className="manage-billing-area">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <h3>EXISTING CARD</h3>
                  <Row>
                    <Col md={{ span: 4, offset: 2 }}>
                      <p>
                        Card added by a workspace owner
                        <br />
                        card ending in 1004.
                      </p>
                    </Col>
                    <Col md={{ span: 4 }}>
                      <Button variant="blue" type="submit">
                        DEFAULT CARD
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Form>
                      <Col md={12}>
                        <h3>ADD NEW CARD</h3>
                      </Col>
                      <Col md={{ span: 8, offset: 2 }}>
                        <div className="form-box">
                          <div className="form-body">
                            <Row>
                              <Col xs={12}>
                                <Form.Group controlId="name_card">
                                  <Form.Label>
                                    <span>*</span>
                                    Name on Card
                                    {' '}
                                  </Form.Label>
                                  <div className="secure-form"> </div>
                                  <Form.Control type="text" />
                                </Form.Group>

                                <Form.Group controlId="card_number">
                                  <Form.Label>
                                    <span>*</span>
                                    Card Number
                                    {' '}
                                  </Form.Label>
                                  <Form.Control type="text" />
                                </Form.Group>

                                <table className="card-expiry-table">
                                  <tbody>
                                    <tr>
                                      <td style={{ width: '110px' }}>
                                        <Form.Group controlId="expiry">
                                          <Form.Label>
                                            <span>*</span>
                                            Expiry
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="MM/YY"
                                          />
                                        </Form.Group>
                                      </td>
                                      <td style={{ width: '90px' }}>
                                        <Form.Group controlId="csv">
                                          <Form.Label>
                                            <span>*</span>
                                            CSV
                                          </Form.Label>
                                          <Form.Control
                                            maxLength="3"
                                            type="password"
                                          />
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <img src={csv} alt="boomea" width={42} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: '110px' }}>
                                        <Form.Group controlId="zip">
                                          <Form.Label>
                                            <span>*</span>
                                            Zip Code
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="MM/YY"
                                          />
                                        </Form.Group>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="3">
                                        <p>
                                          Your card will not be charged at this
                                          time, but will be set as the default for
                                          future charges
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Col>
                            </Row>
                          </div>
                          <div className="form-footer">
                            <Row>
                              <Col xs={6}>
                                <Button variant="blue" type="submit">
                                  SAVE
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Form>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </DashBoard>
  </Layout>
);

export default PaymentMethod;
