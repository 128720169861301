// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import list from './List';
import deleteCompDir from './Delete';
import post from './Post';
import put from './Put';
import postMulti from './PostMulti';

export default combineReducers({
  list,
  post,
  deleteCompDir,
  put,
  postMulti,
});
