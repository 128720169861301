// Copyright 2019, White Label Communications, LLC, All rights reserved.

// import fetch from 'cross-fetch';

export const FETCH_VOICE_RETENTION_START = 'FETCH_VOICE_RETENTION_START';
function fetchVoiceRetentionStart() {
  return {
    type: FETCH_VOICE_RETENTION_START,
  };
}

export const FETCH_VOICE_RETENTION_SUCCESS = 'FETCH_VOICE_RETENTION_SUCCESS';
function fetchVoiceRetentionSuccess(json) {
  return {
    type: FETCH_VOICE_RETENTION_SUCCESS,
    data: json,
  };
}

export const FETCH_VOICE_RETENTION_ERROR = 'FETCH_VOICE_RETENTION_ERROR';
function fetchVoiceRetentionError(json) {
  return {
    type: FETCH_VOICE_RETENTION_ERROR,
    error: json.message,
  };
}

export function fetchVoiceRetention(teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(fetchVoiceRetentionStart());
    const fetchobj = {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/voice_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(fetchVoiceRetentionError(responseJson));
        } else {
          dispatch(fetchVoiceRetentionSuccess(responseJson));
        }
      })
      .catch((error) => {
        console.log(error, ': error'); // eslint-disable-line no-console
        if (error.message) {
          dispatch(fetchVoiceRetentionError(error));
        } else {
          dispatch(fetchVoiceRetentionError({ message: 'API error' }));
        }
      });

    return { data: jsonResponse };
  };
}

export const VOICE_RETENTION_POST_START = 'VOICE_RETENTION_POST_START';
function voiceRetentionPostStart() {
  return {
    type: VOICE_RETENTION_POST_START,
  };
}

export const VOICE_RETENTION_POST_SUCCESS = 'VOICE_RETENTION_POST_SUCCESS';
function voiceRetentionPostSuccess(response) {
  return {
    type: VOICE_RETENTION_POST_SUCCESS,
    data: response,
    timestamp: Date.now(),
  };
}

export const VOICE_RETENTION_POST_ERROR = 'VOICE_RETENTION_POST_ERROR';
function voiceRetentionPostError(error) {
  const ret = {
    type: VOICE_RETENTION_POST_ERROR,
    error: error.message,
  };
  return ret;
}

export function postVoiceRetention(formData, teamId) {
  return async (dispatch) => {
    const jsonResponse = true;
    dispatch(voiceRetentionPostStart());
    // console.log('voiceRetention2: ', formData); // eslint-disable-line no-console
    const fetchobj = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v4/admin/teams/${teamId}/voice_retention`;
    await fetch(
      url,
      fetchobj,
    )
      .then((response) => (response.json()))
      .then((responseJson) => {
        if (responseJson.status_code && responseJson.status_code !== 200) {
          dispatch(voiceRetentionPostError(responseJson));
        } else {
          dispatch(voiceRetentionPostSuccess(responseJson));
        }
      })
      .catch((error) => {
        if (error.message) {
          dispatch(voiceRetentionPostError(error));
        } else {
          dispatch(voiceRetentionPostError({ message: 'API error' }));
        }
      });
    dispatch(voiceRetentionPostSuccess(formData));
    return { data: jsonResponse };
  };
}
