// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import {
  getUsers,
  getUsersCsv,
  postUser,
  forceLogout,
  sendPasswordreset,
  sendReInvitation,
  getTeam,
} from 'actions/UsersActions';
import {
  setTeamModal,
} from 'actions/LoginActions';
import { setCurrentTeamId } from 'actions/TeamActions';
import UsersList from './UsersList';

const mapStateToProps = (state) => {
  const { login, currentTeam, usersCurrent } = state.Ui;
  const { teams, usersCompleteList } = state.Entities;
  return {
    loginData: login,
    currentTeam,
    usersCurrent,
    usersCompleteList,
    teams: teams.data,
  };
};

const mapDispatchToProps = {
  getUsers,
  getUsersCsv,
  postUser,
  forceLogout,
  sendPasswordreset,
  sendReInvitation,
  getTeam,
  setCurrentTeamId,
  setTeamModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
