// Copyright 2019, White Label Communications, LLC, All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import {
  Modal,
  Button,
} from 'react-bootstrap';

const ConfirmModal = (props) => {
  const {
    show,
    confirmModalHeader,
    confirmModalMessage,
    handleCancel,
    handleConfirm,
    confirmButtonText,
    data,
  } = props;

  return (
    <Modal show={show} className="add-cduser" animation>
      <Modal.Header>
        <Modal.Title>
          {confirmModalHeader}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmModalMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="blue"
          onClick={() => handleConfirm(data)}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  confirmModalHeader: PropTypes.string,
  confirmModalMessage: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  data: PropTypes.string,
};

ConfirmModal.defaultProps = {
  show: false,
  confirmModalHeader: 'Confirm',
  confirmModalMessage: 'Are you sure you want to Proceed',
  confirmButtonText: 'Confirm',
  data: '',
};

export default ConfirmModal;
