// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import RightArrow from 'images/right-arrow.svg';

const StepOne = ({ handleInputChange, nextStep, values }) => {
  const companyName = values.companyName.trim();
  const isValid = (companyName && companyName !== '' && companyName.length > 0);

  return (
    <>
      <div className="form-area step-one">
        <h1>What is your company or team name</h1>
        <input
          type="text"
          className="team-name"
          placeholder="Your company or team name"
          onChange={handleInputChange}
          value={values.companyName}
          name="companyName"
          onKeyPress={(event) => {
            if (event.key === 'Enter' && isValid) {
              nextStep();
            }
          }}
        />
        <button disabled={!isValid} type="button" className="entry-button" onClick={nextStep}>
          NEXT
          <img src={RightArrow} alt="boomea" />
          {' '}
        </button>
      </div>
    </>
  );
};

StepOne.propTypes = {
  nextStep: PropTypes.func,
  handleInputChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.string,
      ),
    ]),
  ).isRequired,
};

StepOne.defaultProps = {
  nextStep: () => {},
};

export default StepOne;
