// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import InvoiceDownload from 'images/download-blue.svg';

const HistoryList = () => (
  <>
    <tr className="billing-history-list">
      <td>April 29</td>
      <td>
        <a href="/#">Monthly Statement (PDF)</a>
        <small>Billing statement #54864956048</small>
      </td>
      <td />
      <td />
      <td />
    </tr>
    <tr className="billing-history-list">
      <td>April 29</td>
      <td>
        Swithced from 2 to 1 user
        <small>Billing statement #54864956999</small>
      </td>
      <td>Credited $ 8.88</td>
      <td>$ 91.88</td>
      <td>
        <img src={InvoiceDownload} alt="download" width="32" />
      </td>
    </tr>
  </>
);

export default HistoryList;
