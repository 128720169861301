// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';

export default function PermissionError() {
  return (
    <>
      You don&apos;t have sufficient permission to access boomea admin portal. Please consult with your team Owner
    </>
  );
}
