// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  INTEGRATION_POST_START,
  INTEGRATION_POST_SUCCESS,
  INTEGRATION_POST_ERROR,
} from 'actions/IntegrationSettingActions';

const initialState = {
  status: 0, // 0=started request(init state), 1=loading/checking, 2=error, 3=comp directory post request completed
  error: '', // holds error mess when status=2
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INTEGRATION_POST_START:
      return {
        ...state,
        status: 1, // drives isLoading in UI
        error: '',
      };
    case INTEGRATION_POST_ERROR:
      return {
        ...state,
        status: 2, // this status will turn off isLoading
        error: action.error, // store error in state, UI can use it if appropriate
      };
    case INTEGRATION_POST_SUCCESS:
      return {
        ...state,
        status: 3,
        error: '',
      };
    default:
      return state;
  }
}
