// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import LeftArrow from 'images/left-arrow.svg';
import PropTypes from 'prop-types';
import RightArrow from 'images/right-arrow.svg';

const StepFour = ({ values, previousStep, nextStep }) => (
  <>
    <div className="form-area step-four">
      <div className="form-group">
        <table>
          <tbody>
            <tr>
              <td>Company/Team Name:</td>
              <td>
                <div className="h4">{values.companyName}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="form-group">
        <table>
          <tbody>
            <tr>
              <td>Email:</td>
              <td>
                <div className="h4">{values.adminEmail}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button type="button" className="back-button" onClick={previousStep}>
        <img src={LeftArrow} alt="boomea" />
        {' '}
      </button>
      <button type="button" className="entry-button" onClick={nextStep}>
        NEXT
        <img src={RightArrow} alt="boomea" />
        {' '}
      </button>
    </div>
  </>
);

StepFour.propTypes = {
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.string,
      ),
    ]),
  ).isRequired,
};

StepFour.defaultProps = {
  previousStep: () => {},
  nextStep: () => {},
};

export default StepFour;
