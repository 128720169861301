// Copyright 2019, White Label Communications, LLC, All rights reserved.

import {
  FETCH_LOGOUT_SUCCESS,
  FETCH_COOKIE_LOGIN_SUCCESS,
  FETCH_LOGIN_SUCCESS,
} from 'actions/LoginActions';
import {
  TEAM_DETAILS,
  USERS_COMPLETE_LIST,
  USER_STATUS_POST_SUCCESS,
  USER_POST_SUCCESS,
} from 'actions/UsersActions';
import {
  ConvertArrayOfObjectsToObjectOfIdWithObjectAndCurrentState,
} from 'reducers/Helpers';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEAM_DETAILS:
      return { ...state, teamDetails: action.data };
    case USERS_COMPLETE_LIST:
      return { ...state, ...ConvertArrayOfObjectsToObjectOfIdWithObjectAndCurrentState(state, action.data.records) };
    case USER_STATUS_POST_SUCCESS:
      return {
        ...state,
        [action.data[0].user_id]: {
          ...state[action.data[0].user_id],
          ...action.data[0],
        },
      };
    case USER_POST_SUCCESS:
    case FETCH_LOGIN_SUCCESS:
    case FETCH_COOKIE_LOGIN_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case FETCH_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}
