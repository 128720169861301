// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import Avatar from 'images/avatar.svg';

const ActivityList = () => (
  <tr className="activity-list">
    <td>Tuesday, April 2nd</td>
    <td>
      <img src={Avatar} className="list-avatar" width={32} alt="boomea" />
      {' '}
      <a href="/#">Stan Lee</a>
      was detected as
      <span>inactive </span>
    </td>
    <td />
  </tr>
);

export default ActivityList;
