// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
// import { createStateSyncMiddleware } from 'redux-state-sync';
import Router from './Router';
import './scss/style.scss';
import rootReducer from './reducers';
// import { createMiddlewareWebsocket } from './middlewareWebsocket';

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// websocket middle little diff fromlogger in that it needs to create and hold the socket var
// const middlewareWebsocket = createMiddlewareWebsocket()

// const syncConfig = {
//   // TOGGLE_TODO will not be triggered in other tabs
//   blacklist: ['INCREMENT3'],
// };

const store = createStore(persistedReducer, composeEnhancers(
  // applyMiddleware(..., middlewareWebsocket, ...),
  // applyMiddleware(thunk, createStateSyncMiddleware(syncConfig)),
  applyMiddleware(thunk),
));
// const store = createStore(rootReducer, composeEnhancers(
//   applyMiddleware(thunk),
// ));

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
