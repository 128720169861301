// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import list from './List';
import post from './Post';
import all from './All';


export default combineReducers({
  list,
  post,
  all,
});
