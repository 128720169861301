// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Form, Button,
} from 'react-bootstrap';
import { countTeamAdminInTeams } from 'components/Common/Utils';
import ListRow from 'components/Common/ListRow';


class SwitchTeam extends React.PureComponent {
  render() {
    const {
      show,
      onCancel,
      teams,
      handleTeamChosen,
      currentTeam,
    } = this.props;
    let content = '';
    const teamsArr = Object.keys(teams).map((teamId) => teams[teamId]);
    const adminTeams = countTeamAdminInTeams(teamsArr);
    if (teams && adminTeams.length > 0) {
      content = adminTeams.map((team) => (
        <ListRow
          key={team.id}
          obj={team}
          objChosen={handleTeamChosen}
          isSelected={currentTeam.currentTeamId === team.id ? 1 : 0}
        />
      ));
    }
    return (
      <Modal show={show} className="add-cduser switch-team" animation>
        <Form>
          <Modal.Header>
            <Modal.Title>SWITCH TEAM</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filtered-user-list">
              <div
                className="more-modal__list"
              >
                {content}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

SwitchTeam.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleTeamChosen: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  teams: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};

export default SwitchTeam;
