// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import Entities from './Entities';
import Ui from './Ui';

export default combineReducers({
  Entities,
  Ui,
});
