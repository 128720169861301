// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import signUp from './SignUp';
import signUpConfirm from './SignUpConfirm';
import signUpFinal from './SignUpFinal';

export default combineReducers({
  signUp,
  signUpConfirm,
  signUpFinal,
});
