// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { connect } from 'react-redux';
import { fetchCookieLogin } from 'actions/LoginActions';
import CookieLogin from './CookieLogin';

const mapStateToProps = (state) => (
  {
    login: state.Ui.login,
  }
);

const mapDispatchToProps = {
  fetchCookieLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieLogin);
